<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      {{ brand.url }} is not connected with or endorsed by the United States
      government or the Federal Medicare program. Not all products are available
      in all states. Products are available in the following states: AK, AL, AR,
      AZ, CA, CO, CT, DC, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME,
      MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NV, OH, OK, OR, PA, RI, SC, SD,
      TN, TX, UT, VA, VT, WI, WV and WY. There is absolutely no obligation to
      purchase anything. At {{ brand.name }}, you will talk with a licensed
      insurance agent in your state.

      <br />
      <br />

      {{ brand.url }} is a licensed and certified representative of Medicare
      Advantage [HMO, HMO SNP, PPO, PPO SNP and PFFS] organizations [and
      Medicare-approved Part D sponsors] that have a Medicare contract.
      Enrollment in any plan depends on contract renewal. Not all plans offer
      all of these benefits. Benefits may vary by carrier and location.
      Limitations and exclusions may apply. Calling this number
      <ClickToCall v-slot="{ handleClick }">
        <a :href="phone.uri" @click="handleClick">
          {{ phone.formatted }}
        </a>
      </ClickToCall>
      TTY 711 24/7 will connect you to a licensed insurance agent.

      <br />
      <br />

      By using this site, you acknowledge that you have read and agree to the
      <Link to="/tos">Terms of Service</Link>
      and
      <Link to="/privacy">Privacy Policy</Link>.

      <br />
      <br />

      <FooterBenefits />

      Certain individuals might qualify for assistance paying their Part B
      Premium ($164.90) as part of their state's Medicaid and/or Medical
      Assistance Program. Acceptance into these programs is not guaranteed. We
      can assist with initial eligibility screenings, but only the state
      agencies can determine final eligibility. The giving of financial
      information is optional and does not affect enrollment eligibility.

      <br />
      <br />

      We do not offer every plan available in your area. Any information we
      provide is limited to those plans we do offer in your area. Please contact
      Medicare.gov or 1-800-MEDICARE to get information on all of your options.
      (TTY users should call 1-877-486-2048) 24 hours a day/7 days a week.

      <br />
      <br />

      Enrollment in the described plan type may be limited to certain times of
      the year unless you qualify for a Special Enrollment Period.

      <br />
      <br />

      <div class="sm:hidden">
        <div class="flex items-center space-x-6 my-2">
          <img src="../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../assets/comodo-badge.svg" width="107" height="34" />
        </div>
        <br />
      </div>

      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
      reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>
    </div>
  </div>
</template>
