<script setup lang="ts">
import oepTimeline from '../../assets/medicare-benefits-oep-timeline.webp'
import sepTimeline from '../../assets/medicare-benefits-sep-timeline.webp'
import iepTimeline from '../../assets/medicare-benefits-iep-timeline.webp'
import testimonial1 from '../../assets/testimonial-1.jpeg'
import testimonial2 from '../../assets/testimonial-2.jpeg'

import iconCheck from '../../assets/mb/check.svg'
import iconClipboardShield from '../../assets/mb/clipboard-shield.svg'
import iconCoinsHand from '../../assets/mb/coins-hand.svg'
import iconDoctor from '../../assets/mb/doctor.svg'
import iconHearing from '../../assets/mb/hearing.svg'
import iconMoneyPig from '../../assets/mb/money-pig.svg'
import iconOtc from '../../assets/mb/otc.svg'
import iconRun from '../../assets/mb/run.svg'
import iconRx from '../../assets/mb/rx.svg'
import iconTransportation from '../../assets/mb/transportation.svg'

const featuredOn = [
  {
    id: 'fox',
  },
  {
    id: 'forbes',
  },
]

const benefits = [
  {
    icon: iconMoneyPig,
    text: '$0 Monthly Plan Premiums',
  },
  {
    icon: iconCoinsHand,
    text: '$0 Primary Care Doctor Copays',
  },
  {
    icon: iconRx,
    text: '$0 Annual Rx Deductibles',
  },
  {
    icon: iconRun,
    text: 'Fitness Club Allowance',
  },
  {
    icon: iconDoctor,
    text: '$0 Specialist Copays',
  },
  {
    icon: iconTransportation,
    text: 'Doctor Visits Transportation Allowance',
  },
  {
    icon: iconCheck,
    text: '$500 OOP Maximum',
  },
  {
    icon: iconOtc,
    text: '$780 Annual OTC Benefit',
  },
  {
    icon: iconClipboardShield,
    text: '$6.90/mo. Lowest Part D Premium',
  },
  {
    icon: iconHearing,
    text: '$0 Copay on Hearing Benefits',
  },
]

const bundleYourCoverage = [
  {
    icon: 'i-fa6-solid:medal',
    text: 'Medicare Part A & B',
  },
  {
    icon: 'i-fa6-solid:capsules',
    text: 'Prescription Drugs',
  },
  {
    icon: 'i-fa6-solid:medal',
    text: 'Routine Dental Services',
  },
  {
    icon: 'i-fa6-solid:medal',
    text: 'Routine Vision Services',
  },
  {
    icon: 'i-fa6-solid:medal',
    text: 'Routine Hearing Exams',
  },
]

const periods = [
  {
    id: 'oep',
    title: 'Medicare Advantage Open Enrollment Period',
    description: 'Jan. 1 - March 31, 2023',
    image: oepTimeline,
    timeline: ['January', 'February', 'March'],
  },
  {
    id: 'sep',
    title: 'Special Enrollment Periods',
    description:
      'Triggered by specific life events - including moving, loss of coverage, and other special situations - throughout the year.',
    image: sepTimeline,
  },
  {
    id: 'iep',
    title: 'Medicare Initial Enrollment Period (IEP) Timeline',
    description: 'Enroll during IEP to avoid late penalties',
    image: iepTimeline,
    timeline: ['Start of Your IEP', 'Your Birthday Month', 'End of Your IEP'],
  },
]

const badges = [
  {
    id: 'hipaa',
  },
  {
    id: 'godaddy',
  },
  {
    id: 'ssl',
  },
]

const why = [
  {
    title: 'A Wide Selection',
    description:
      "Save time and shop a wide variety of Medicare solutions from the nation's top-rated2 insurance companies in one place.",
  },
  {
    title: 'Shopping Made Easy',
    description:
      'Our licensed insurance agents do the hard work for you. Not ready to talk? Find products, compare rates, and in some cases, even enroll online.',
  },
  {
    title: 'Fast, Reliable Support',
    description:
      "Have questions about your new coverage? We're here to help at every step - including before, during and after your enrollment.",
  },
]

// news
// small icons
// 3 icons

// form go to go4benefits, skip zipcode step if already have it (don't ask again)
</script>

<template>
  <Layout>
    <!-- hero -->
    <div>
      <div
        class="container py-8 grid items-center justify-items-center gap-8 md:grid-cols-3"
      >
        <div class="relative w-48 h-48 flex items-center justify-center">
          <div class="bg-gray-200 w-40 h-40 rounded-full"></div>
          <StateMap
            v-if="ip.lookup?.stateCode"
            :state-code="ip.lookup.stateCode"
            class="absolute inset-0 text-red"
          />
        </div>

        <div class="md:col-span-2">
          <div class="text-3xl font-medium">
            Medicare Advantage in {{ ip.lookup?.stateName || 'America' }}
          </div>
          <div class="text-lg mt-2 mb-8">
            Give us a call or fill out the form below, and we'll help you find
            the right Medicare Advantage Plan that best fits your lifestyle.
          </div>
          <div class="bg-white rounded-md shadow p-4 max-w-xl">
            <MedicareBenefitsForm />
          </div>
        </div>
      </div>
    </div>

    <!-- logos -->
    <div class="bg-darkBlue text-white">
      <div class="container py-8">
        <div class="text-lg text-center"> As featured on </div>

        <div>
          <div v-for="item in featuredOn" :key="item.id">
            <!-- news logo -->
          </div>
        </div>
      </div>
    </div>

    <!-- benefits -->
    <div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Medicare Advantage Benefits in {{ ip.lookup?.stateName || 'America' }}
        </div>
        <div class="text-lg mt-2 mb-8 text-center">
          Medicare Advantage Plans in
          {{ ip.lookup?.stateName || 'America' }} may include benefits such as:1
        </div>

        <div class="grid gap-2 md:grid-cols-2">
          <div
            v-for="item in benefits"
            :key="item.text"
            class="bg-white rounded-md shadow p-4 flex items-center gap-4"
          >
            <img :src="item.icon" width="64" height="64" />
            <div class="flex-1"> {{ item.text }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- bundle your coverage -->
    <div class="bg-gray-200">
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Medicare Advantage: Bundle Your Coverage Into One Convenient Plan
        </div>
        <div class="text-lg mt-2 mb-8 text-center">
          Medicare Advantage Plans, sometimes called Medicare Part C, replaces
          your Original Medicare and may come with additional benefits normally
          found in separate plans, such as hearing, dental, vision and
          prescription drugs.1
        </div>

        <div class="grid justify-items-center">
          <div class="space-y-1">
            <div
              v-for="item in bundleYourCoverage"
              :key="item.text"
              class="flex items-center gap-4"
            >
              <!-- <Icon v-bind="{ [item.icon]: '' }" class="w-4 h-4" /> -->
              <div class="text-green-600"> icon </div>
              <div class="flex-1"> {{ item.text }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- enrollment periods -->
    <div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          2023 Important Enrollment Periods in
          {{ ip.lookup?.stateName || 'America' }}
        </div>

        <div class="my-8 space-y-8 max-w-xl mx-auto">
          <div
            v-for="period in periods"
            :key="period.id"
            class="bg-white rounded-md shadow py-6 px-4"
          >
            <div class="text-xl font-medium text-center">
              {{ period.title }}
            </div>
            <div class="mt-1 text-center text-gray-600">
              {{ period.description }}
            </div>
            <img :src="period.image" class="mt-4" />
            <div
              v-if="period.timeline"
              class="mt-1 grid grid-cols-3 gap-2 text-sm text-gray-500"
            >
              <div class="text-left"> {{ period.timeline[0] }} </div>
              <div class="text-center"> {{ period.timeline[1] }} </div>
              <div class="text-right"> {{ period.timeline[2] }} </div>
            </div>
          </div>
        </div>

        <div class="text-lg text-center">
          Medicare Annual Enrollment Period:
          <span class="whitespace-nowrap"> Oct. 15 - Dec. 7, 2023 </span>
        </div>
      </div>
    </div>

    <!-- form -->
    <div class="bg-gray-200">
      <div class="relative">
        <img
          src="../../assets/medicare-benefits-form.jpeg"
          width="768"
          height="347"
          class="mx-auto w-xl"
        />
        <div class="absolute inset-0 bg-gradient-to-t from-gray-200"></div>
      </div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Ready to Find Your Plan?
        </div>
        <div class="text-lg mt-2 mb-8 text-center">
          Plan premiums starting at $0/mo1 in many areas
        </div>

        <div class="bg-white rounded-md shadow p-4">
          <MedicareBenefitsForm />
        </div>

        <div class="flex items-center justify-center gap-6 mt-8">
          <img src="../../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../../assets/comodo-badge.svg" width="107" height="34" />
        </div>
      </div>
    </div>

    <!-- why -->
    <div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Why Choose Ensurem?
        </div>

        <div class="mt-8 grid gap-8 md:grid-cols-3">
          <div v-for="item in why" :key="item.title">
            <div class="text-green-600"> icon </div>
            <div class="text-lg font-medium mt-2 mb-1"> {{ item.title }} </div>
            <div class="text-gray-600"> {{ item.description }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="bg-darkBlue">
      <div class="container py-8">
        <Testimonials
          :testimonials="[
            {
              photo: testimonial1,
              name: 'John Peterson',
              text: 'Great help from Laura, and a plan with extras! Laura was so helpful to me. She took the time to explain my new health plan. Now, I\'ve got a plan that is just like my old one, but better. The best part is it includes dental coverage! I am so happy I won\'t have to worry about my teeth anymore. Thanks to Laura and Humana!',
            },
            {
              photo: testimonial2,
              name: 'Mary Sullivan',
              text: 'Fantastic service and Part B giveback! I had a great talk with Steven about my Medicare Advantage plan. He was really nice and explained everything clearly. I\'m really excited about the Part B giveback feature in my plan. It feels good knowing I\'ll be getting money back. Thank you, Steven!',
            },
          ]"
        />
      </div>
    </div>
  </Layout>
</template>
