<template>
  <div class="bg-white shadow sticky top-0 z-20">
    <slot name="banner" />
    <div class="container flex items-center space-x-4 py-2">
      <RouterLink to="/" class="mr-auto">
        <LogoSmall class="sm:hidden" />
        <Logo class="hidden sm:block" />
      </RouterLink>

      <div class="text-right text-sm text-gray-500">
        <div> Call a licensed insurance agent </div>
        <div class="flex items-center justify-end gap-2">
          <div class="relative flex w-2 h-2">
            <div
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
            >
            </div>
            <div class="relative inline-flex rounded-full h-2 w-2 bg-green-500">
            </div>
          </div>
          <div class="text-2xl leading-7 text-red">
            <ClickToCall
              :props="{ ctcLocation: 'header' }"
              v-slot="{ handleClick }"
            >
              <a :href="phone.uri" @click="handleClick">
                {{ phone.formatted }}
              </a>
            </ClickToCall>
          </div>
        </div>
        <div>
          TTY Users:
          <span class="text-2xl leading-7 text-red"> 711, 24/7 </span>
        </div>
      </div>
    </div>
  </div>
</template>
