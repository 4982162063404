<script setup lang="ts">
const props = defineProps<{
  variant?: 'red' | 'gray' | 'darkBlue' | 'yellow' | 'blue' | 'transparent'
  type?: 'button' | 'submit'
  size?: 'sx' | 'sm' | 'md' | 'lg'
  to?: string
  wide?: boolean
  loading?: boolean
  disabled?: boolean
}>()

const disabled = computed(() => !!(props.disabled || props.loading))
const variant = computed(() => props.variant || 'red')
const type = computed(() => props.type || 'button')
const size = computed(() => props.size || 'md')

const component = computed(() => {
  return props.to ? (props.to.startsWith('/') ? 'RouterLink' : 'a') : 'button'
})

const touching = ref(false)

const attrs = computed(() => {
  return component.value === 'RouterLink'
    ? { to: props.to }
    : component.value === 'a'
    ? { href: props.to }
    : { type: type.value, disabled: disabled.value }
})
</script>

<template>
  <component
    :is="component"
    :class="[
      variant === 'red' && 'text-white border-transparent shadow-sm',
      variant === 'red' && !touching && 'bg-red active:bg-red-dark',
      variant === 'red' && touching && 'bg-red-dark',
      variant === 'transparent' &&
        'bg-transparent text-white hover:text-gray-500 border-transparent',
      variant === 'gray' &&
        'bg-transparent hover:bg-gray-200 text-gray-400 hover:text-gray-500 border-transparent',
      variant === 'darkBlue' && 'text-white border-transparent shadow-sm',
      variant === 'darkBlue' &&
        !touching &&
        'bg-darkBlue active:bg-darkBlue-dark',
      variant === 'darkBlue' && touching && 'bg-darkBlue-dark',
      variant === 'yellow' &&
        'bg-yellow-400 text-darkBlue border-transparent shadow-sm',
      variant === 'blue' && 'bg-sky-700	text-white border-transparent shadow-sm',
      size === 'sx' && 'py-3 px-10 text-sx sm:text-base font-medium',
      size === 'sm' && 'py-3 px-6 text-base sm:text-xl font-medium',
      size === 'md' &&
        'py-3 px-6 text-size-[1.375rem] leading-7 font-semibold uppercase tracking-wider',
      size === 'lg' &&
        'py-4 px-6 text-xl sm:text-4xl leading-7 font-bold uppercase tracking-wider',
      disabled && 'opacity-70 pointer-events-none',
      wide ? 'flex w-full' : 'inline-flex',
      'relative border rounded-md outline-none justify-center',
    ]"
    v-bind="attrs"
    @touchstart="touching = true"
    @touchend="touching = false"
  >
    <span
      :class="[loading && 'invisible', 'flex items-center pointer-events-none']"
    >
      <slot />
    </span>
    <span
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <Spinner white />
    </span>
  </component>
</template>
