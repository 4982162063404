<script setup lang="ts">
const route = useRoute()

const input = route.params.state as string

const state = states.find(
  (state) => state.name.replace(' ', '-').toLowerCase() === input
)!

const head = {
  title: `Find Medicare quotes in ${state.name} with ${brand.name}`,
}

const page = {
  name: 'Medicare Fixed State LP',
  category: 'LP',
  product: 'medicare',
}
</script>

<template>
  <LandingPageMedigap :head="head" :page="page">
    <template #title>
      New 2023 Medicare Insurance Plans in
      <Underline> {{ state.code }} </Underline>
    </template>
    <template #subtitle>
      Learn about Medicare Advantage plans with additional benefits.
    </template>
  </LandingPageMedigap>
</template>

<route lang="json">
{
  "path": "/in/:state(alabama|alaska|arizona|arkansas|california|colorado|connecticut|delaware|florida|georgia|hawaii|idaho|illinois|indiana|iowa|kansas|kentucky|louisiana|maine|maryland|massachusetts|michigan|minnesota|mississippi|missouri|montana|nebraska|nevada|new-hampshire|new-jersey|new-mexico|new-york|north-carolina|north-dakota|ohio|oklahoma|oregon|pennsylvania|rhode-island|south-carolina|south-dakota|tennessee|texas|utah|vermont|virginia|washington|west-virginia|wisconsin|wyoming)",
  "meta": {
    "smid": {
      "m10": "MULTI-PLAN_EHWEB092201_M",
      "eh": "MULTI-PLAN_EHWEB092202_M",
      "default": "MULTIPLAN_EHWEB0819v1_M"
    },
    "robots": "noindex"
  }
}
</route>
