<script setup lang="ts">
const props = defineProps<{
  title: string
  text: string
  image: string
  active: boolean
}>()

const activeClass = computed(() => {
  const { active } = props
  return active
    ? 'animate-slideInFromLeft animate-fade-in'
    : 'animate-slideOutToRight animate-fade-out'
})
</script>

<template>
  <div :class="['absolute flex flex-col h-full w-full top-0', activeClass]">
    <div class="image-holder max-w-[860px] max-h-[500px] rounded-3xl">
      <img :src="image" />
    </div>
    <div
      class="block max-w-sm py-12 px-4 md:p-16 w-full md:min-w-[520px] flex gap-8 flex-col bg-white rounded-3xl shadow-md h-[300px] md:h-[520px] p-10 absolute md:right-[5%] md:bottom-[5%] bottom-[30px]"
    >
      <h3 class="text-3xl md:text-5xl">{{ title }}</h3>
      <p class="text-lg md:text-2xl">{{ text }}</p>
    </div>
  </div>
</template>
