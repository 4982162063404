<script setup lang="ts">
const router = useRouter()
const route = useRoute()

onMounted(() => {
  router.replace({
    path: '/call-4-extra-benefits',
    query: route.query,
  })
})
</script>

<template>
  <div></div>
</template>

<route lang="json">
{
  "meta": {
    "robots": "noindex"
  }
}
</route>
