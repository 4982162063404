<script setup lang="ts">
const props = defineProps<{
  to: string
}>()

const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`${props.to}/?state=${values.state}`)
})
</script>

<template>
  <form class="max-w-xl mx-auto grid gap-4" @submit="onSubmit">
    <Select
      name="state"
      initialValue="AL"
      :options="
        states.map((state) => ({ value: state.code, text: state.name }))
      "
    />
    <Button wide type="submit"> Compare Plans </Button>
  </form>
</template>
