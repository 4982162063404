<script setup lang="ts">
const props = defineProps<{
  to: string
}>()

const component = computed(() => {
  return props.to.startsWith('/') ? 'RouterLink' : 'a'
})

const attrs = computed(() => {
  return component.value === 'RouterLink'
    ? { to: props.to }
    : { href: props.to }
})
</script>

<template>
  <component :is="component" v-bind="attrs" class="text-blue-900 underline">
    <slot />
  </component>
</template>
