export const maLoaded = ref(false)

onInit(({ isClient }) => {
  if (isClient) {
    const { load } = useScriptTag(
      'https://insurance.mediaalpha.com/js/serve.js',
      () => {
        maLoaded.value = true
      },
      {
        manual: true,
      }
    )
    load()
  }
})
