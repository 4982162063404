<script setup lang="ts">
export type CarouselItem = {
  img: string
  title: string
  text: string
}
const props = defineProps<{
  items: CarouselItem[]
}>()

const activeItem = ref(0)
let interval: NodeJS.Timeout

const createInterval = () => {
  cancelInterval()
  interval = setTimeout(() => {
    if (activeItem.value < props.items.length - 1) {
      onNextItem()
      return
    }
    onSelectItem(0)
  }, 4000)
}

const cancelInterval = () => {
  clearTimeout(interval)
}

const onSelectItem = (index: number) => {
  activeItem.value = index
  createInterval()
}
const onNextItem = () => {
  onSelectItem(activeItem.value + 1)
}

onMounted(() => createInterval())
</script>

<template>
  <h2 class="text-3xl md:text-6xl text-left max-w-[640px] leading-tight py-4"
    ><span>Discover the EasyHealth </span>
    <span class="border-b-4 border-yellow-400">Difference</span>
  </h2>
  <p class="pb-4 text-2xl max-w-[730px] mb-12">
    EasyHealth is a tech enabled health distribution platform. Our digital
    health brands connect educate health shoppers and drive action.
  </p>
  <div
    class="relative overflow-hidden rounded-md md:h-[660px] h-[530px] w-full"
  >
    <CarouselCard
      v-for="(item, index) in props.items"
      :key="item.title"
      :title="item.title"
      :image="item.img"
      :text="item.text"
      :active="index === activeItem"
    ></CarouselCard>

    <div
      class="controls absolute md:right-[20%] w-[200px] bottom-[10%] h-[20px] left-[20px] md:left-[initial]"
    >
      <button
        @click.prevent="onSelectItem(index)"
        :class="[
          'h-1.5 rounded mr-2',
          `${
            activeItem === index
              ? 'w-16 bg-blue-900 flex-grow'
              : 'w-5 bg-gray-200 '
          }`,
        ]"
        v-for="(_message, index) in props.items"
      ></button>
    </div>
  </div>
</template>
