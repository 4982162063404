<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      Los montos de las asignaciones no se pueden combinar con otras
      asignaciones de beneficios. Pueden aplicarse limitaciones y restricciones

      <br />
      <br />

      Las agencias de ventas participantes representan a las organizaciones de
      Medicare Advantage [HMO, PPO y PFFS] [y los planes de medicamentos
      recetados de PDP independientes] que tienen contrato con Medicare. La
      inscripción depende de la renovación del contrato del plan.

      <br />
      <br />

      No ofrecemos todos los planes disponibles en su área. Actualmente
      representamos a [insertar el número de organizaciones] organizaciones que
      ofrecen [insertar el número de planes] productos en su área. Comuníquese
      con Medicare.gov, al 1-800-MEDICARE o con el Programa Estatal de Seguro de
      Salud (SHIP) local para obtener información sobre todas sus opciones.

      <br />
      <br />

      {{ brand.url }} no está conectado ni respaldado por el gobierno de los
      Estados Unidos o el Programa Federal de Medicare. No todos los productos
      están disponibles en todos los estados. Los productos están disponibles en
      los siguientes estados: AK, AL, AR, AZ, CA, CO, CT, DC, FL, GA, HI, IA,
      ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE, NH,
      NJ, NV, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WI, WV y WY. No
      hay absolutamente ninguna obligación de comprar nada. En {{ brand.url }},
      se comunicará con un agente de seguros autorizado en su estado.

      <br />
      <br />

      {{ brand.url }} es un representante autorizado y certificado de las
      organizaciones HMO, HMO SNP, PPO, PPO SNP y PFFS de Medicare Advantage y
      PDP independientes que tienen un contrato con Medicare. La inscripción en
      cualquier plan depende de la renovación del contrato. No todos los planes
      ofrecen todos estos beneficios. Los beneficios pueden variar según el
      proveedor y la ubicación. Pueden aplicarse limitaciones y exclusiones.
      Llamando a este número
      <ClickToCall v-slot="{ handleClick }">
        <a :href="phone.uri" @click="handleClick">
          {{ phone.formatted }}
        </a>
      </ClickToCall>
      TTY 711, 24/7, se conectará con un agente de seguros autorizado.

      <br />
      <br />

      Esta es una solicitud de seguro. La inscripción en un plan puede estar
      limitada a ciertas épocas del año a menos que califique para un período de
      inscripción especial o esté en su Período de Elección Inicial de Medicare.

      <br />
      <br />

      Al usar este sitio, usted reconoce que ha leído y acepta los
      <Link to="/tos">Términos de Servicio</Link> y la
      <Link to="/privacy">Política de Privacidad</Link>.

      <br />
      <br />

      <FooterBenefits />

      <div class="sm:hidden">
        <div class="flex items-center space-x-6 my-2">
          <img src="../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../assets/comodo-badge.svg" width="107" height="34" />
        </div>
        <br />
      </div>

      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | Todos los
      derechos reservados | 8605 Santa Monica Blvd, PMB 38903, West Hollywood,
      CA 90069

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>
    </div>
  </div>
</template>
