<template>
  <FormProgress />

  <FormTitle>
    Last step, how can we reach you to review your enrollment eligibility and
    review available plans & possible benefits?
  </FormTitle>

  <Field
    type="phone"
    name="primaryphone"
    label="Phone number"
    rules="required"
    :initialValue="query.phone"
  />

  <Field type="email" name="email" label="Email" optional />

  <div class="text-sm sm:text-base text-gray-500 italic text-center">
    By clicking 'Submit', you agree to the terms below.
  </div>

  <div class="flex items-center">
    <FormButtonNext> Submit </FormButtonNext>
    <Button
      size="sm"
      variant="gray"
      @click="$router.push('/quotes/v2/thank-you')"
    >
      Skip
    </Button>
  </div>

  <div class="text-gray-500 text-sm sm:text-base text-justify">
    This is a solicitation for insurance and related services and products
    including Medicare Advantage, Medicare Supplement Insurance, and Medicare
    Prescription Drug Plans and healthcare provider services. There is no
    obligation to enroll. Your inquiry may generate marketing and other
    communications by a licensed insurance agency or other insurance company or
    on behalf of a healthcare provider. Submission of your information does NOT
    affect your current enrollment, nor will it enroll you in a Medicare
    Advantage, Medicare Supplement Insurance plan, or other Medicare plan.
    <br />
    <br />
    By clicking the 'Submit' button on this page, you provide expressed consent
    to receive marketing and other communications from EasyHealth, its
    subsidiaries, and its partner
    <Link to="/carriers" target="_blank">companies</Link> via email, live
    telephone, automatic telephone dialing system, artificial or pre-recorded
    voice message, or SMS/MMS text message at the telephone number provided even
    if the number is on a federal, state, or company Do Not Call list. Your
    consent to receive communications is not required as a condition of
    purchasing any goods or services. Your telephone company may impose
    additional charges for text messages, and you may revoke your consent at any
    time through any reasonable manner including by contacting
    info@joineasyhealth.com.
    <br />
    <br />
    Please see our <Link to="/tos"> Terms and Conditions </Link> and
    <Link to="/privacy"> Privacy Policy </Link> for additional terms.
  </div>
</template>
