interface DataLayer {
  push: (props: object) => void
  get: (prop: string) => any
}

export const dataLayer: DataLayer = reactive({
  push: (props) => {
    if (!import.meta.env.SSR) {
      window.dataLayer.push(props)
    }
  },
  get: (prop) => {
    if (!import.meta.env.SSR) {
      const gtm = window.google_tag_manager
      return (
        gtm &&
        _.find(gtm, (value, key) => key.startsWith('GTM')).dataLayer.get(prop)
      )
    }
  },
})

onInit(() => {
  analytics.ready(() => {
    dataLayer.get = dataLayer.get.bind({})

    window.addEventListener('datalayerpush', () => {
      dataLayer.get = dataLayer.get.bind({})
    })
  })
})
