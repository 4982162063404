<template>
  <FormProgress />

  <FormTitle> What's your full name? </FormTitle>

  <Field
    name="firstname"
    label="First name"
    rules="required"
    autocomplete="given-name"
    :initialValue="query.firstname"
  />

  <Field
    name="lastname"
    label="Last name"
    rules="required"
    autocomplete="family-name"
    :initialValue="query.lastname"
  />

  <FormButtonNext />
  <FormButtonBack />
</template>
