import { load, BotDetectionResult } from '@fingerprintjs/botd'

export const botDetectionResult = ref<BotDetectionResult>()

onInit(({ isClient }) => {
  if (isClient) {
    load()
      .then((botd) => {
        return botd.detect()
      })
      .then((result) => {
        botDetectionResult.value = result
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
  }
})
