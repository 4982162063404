<script setup lang="ts">
import { bypassFilter } from '@vueuse/shared'

const emit = defineEmits<{
  (e: 'return', callDurationMs: number): void
}>()

const props = defineProps<{
  tty?: boolean
  done?: boolean
  props?: Record<string, any>
  text?: string
  disabled?: boolean
  variant?: 'red'
  noPostCallReview?: boolean
}>()

const lastActive = ref(0)

const focused = useWindowFocus()

const showPostCallReviewModal = ref(false)

const handleClick = async (event: Event) => {
  const country = ip.lookup?.countryCode?.toUpperCase()
  if (country && country !== 'US') {
    event.preventDefault()
    return
  }

  const product = dataLayer.get('product') || null
  const didNumber = phone.value

  analytics.track('ctcClicked', {
    product,
    didNumber,
    ...props.props,
  })

  if (props.props?.smsConsent) {
    analytics.track('smsConsentGiven', {
      phone: props.props.smsConsent,
      trustedForm: trustedForm.certUrl,
      origin: 'clickToCall'
    })
  }

  const clickTimestamp = lastActive.value

  await until(focused).toBe(false)

  if (clickTimestamp === lastActive.value) {
    const callStartDate = Date.now()

    await until(focused).toBe(true)

    const callEndDate = Date.now()
    const callDurationMs = callEndDate - callStartDate

    analytics.track('ctcReturned', {
      product,
      didNumber,
      callDurationMs,
    })

    emit('return', callDurationMs)

    if (!props.noPostCallReview) {
      showPostCallReviewModal.value = true
    }
  }
}

onMounted(() => {
  const idle = useIdle(60000, { eventFilter: bypassFilter })

  watchSyncEffect(() => {
    lastActive.value = idle.lastActive.value
  })
})
</script>

<template>
  <slot :handleClick="handleClick">
    <Button
      wide
      size="lg"
      :variant="variant || 'yellow'"
      :disabled="disabled"
      :to="phone.uri"
      @click="handleClick"
    >
      <span v-if="!done" class="relative block w-10 h-10 mr-6">
        <span class="absolute inset-0 flex items-center justify-center">
          <span
            :class="[
              variant === 'red' ? 'bg-white/30' : 'bg-red/30',
              'animate-ping w-8 h-8 rounded-full',
            ]"
          >
          </span>
        </span>
        <span class="absolute inset-0 flex items-center justify-center">
          <Icon
            i-heroicons-solid:phone
            :class="[
              variant === 'red' ? 'text-white' : 'text-red',
              'w-10 h-10 animate-shake',
            ]"
          />
        </span>
      </span>
      <Icon v-else i-heroicons-solid:check class="w-10 h-10 text-red mr-6" />
      <span>
        <slot name="text">
          {{ text || `Call ${phone.formatted}` }}
          <template v-if="tty">
            <br />
            TTY 711
          </template>
        </slot>
      </span>
    </Button>
  </slot>

  <Modal
    :open="showPostCallReviewModal"
    @close="showPostCallReviewModal = false"
  >
    <ModalPostCallReview @close="showPostCallReviewModal = false" />
  </Modal>
</template>
