<script setup lang="ts">
const show = ref(false)

const router = useRouter()
const route = useRoute()

const brandDestination: Record<typeof brand['id'], string | null> = {
  eh: null,
  pc: null,
  bh: '/go-4-benefits',
  m10: '/go-4-benefits',
  ayb: null,
  med10: '/go-4-benefits',
  boomer: null,
}

onMounted(() => {
  const destination = brandDestination[brand.id]
  if (destination) {
    analytics.track('redirected', {
      reason: 'Page not found',
      source: route.fullPath,
      destination,
    })
    router.replace({
      path: destination,
      query: route.query,
    })
  } else {
    show.value = true

    useHead({
      title: `Page not found - ${brand.name}`,
    })

    analytics.page({
      name: '404',
      category: '404',
    })
  }
})
</script>

<template>
  <Layout v-if="show">
    <div class="bg-darkBlue text-white">
      <div class="container py-16 flex flex-col items-center lg:flex-row">
        <img src="../assets/404.svg" class="mb-12 lg:mb-0 lg:mr-12 w-[360px]" />
        <div class="flex-1 text-center lg:text-left">
          <h1 class="text-5xl sm:text-6xl">
            Page <Underline>not found</Underline>
          </h1>
          <div class="mt-6 mb-12 text-2xl text-white text-opacity-75">
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </div>
          <Button to="/"> Go to home </Button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "smid": "MULTIPLAN_EHWEB0819v1_M",
    "robots": "noindex"
  }
}
</route>
