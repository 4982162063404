interface Segment {
  anonymousId: null | string
}

export const segment: Segment = reactive({
  anonymousId: null,
})

onInit(({ isClient }) => {
  if (isClient) {
    segment.anonymousId = window.localStorage.getItem('anonymousId')
  }
})
