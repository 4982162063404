<script setup lang="ts">
const { y } = useWindowScroll()
</script>

<template>
  <div class="bg-white shadow sticky top-0 z-20">
    <slot name="banner" />
    <div class="container flex items-center py-2 relative min-h-[4rem]">
      <RouterLink to="/" class="mr-auto">
        <LogoSmall class="sm:hidden" />
        <Logo class="hidden sm:block" />
      </RouterLink>
      <div
        class="absolute inset-0 flex items-center justify-center pointer-events-none"
      >
        <img
          src="../assets/emily-avatar.png"
          :class="[
            y === 0 && 'translate-y-8 scale-150',
            'w-12 h-12 transition-transform ease-linear duration-200',
          ]"
        />
      </div>
      <ClickToCall :props="{ ctcLocation: 'header' }" v-slot="{ handleClick }">
        <a :href="phone.uri" @click="handleClick">
          <Icon i-heroicons-solid:phone class="w-6 h-6 text-gray-600" />
        </a>
      </ClickToCall>
    </div>
  </div>
</template>
