<script setup lang="ts">
useHead({
  title: 'Thank you - Your Medicare10 Quote Was Successful',
})

analytics.page({
  name: 'TYP 1.0',
  category: 'TYP',
  product: 'medicare',
})
</script>

<template>
  <Layout>
    <!-- hero -->
    <div class="bg-darkBlue text-white">
      <div class="container py-16 flex flex-col items-center lg:flex-row">
        <img
          src="../../assets/confirmed-bro.svg"
          class="w-[460px] -mt-14 lg:-mb-14 lg:mr-8"
        />
        <div class="flex-1 text-center lg:text-left">
          <h1 class="text-5xl sm:text-6xl">
            You're <Underline>all set!</Underline>
          </h1>
          <div class="mt-6 text-2xl text-white text-opacity-75">
            Now all you have to do is follow these 3 steps.
          </div>
        </div>
      </div>
    </div>

    <!-- steps -->
    <div class="container py-24 space-y-24 lg:max-w-4xl">
      <div class="space-y-8 md:space-y-0 md:flex md:space-x-8">
        <div class="box-content w-6 h-6 p-3 bg-darkBlue text-white rounded-md">
          <Icon i-heroicons-outline:phone />
        </div>
        <div class="flex-1 space-y-8">
          <h2 class="text-3xl md:text-4xl">
            1. Expect a call from our agent concierge
          </h2>
          <div>
            <AccordionPanel title="What is an agent concierge?">
              Our agent concierge team is responsible for connecting people like
              yourself with a licensed insurance agent.
            </AccordionPanel>
            <AccordionPanel title="What do I need for my call?">
              Your Medicare10 advocate is going to ask you for your Medicare ID
              Number, so you will want to have your Medicare card handy. Other
              important things you may want to gather include a list of doctors
              you see and the medications you use, your Medicaid ID card (if you
              have one), and information about the healthcare plans you
              currently have.
            </AccordionPanel>
            <AccordionPanel title="What if I can't take a call right now?">
              That's okay! We want to talk with you when you're ready to
              prioritize your healthcare. If that isn't right now, we'll give
              you a few more calls in the coming days. Plus, you're always free
              to give us a call at
              <ClickToCall v-slot="{ handleClick }: any">
                <Link :to="phone.uri" @click="handleClick">
                  {{ phone.formatted }} </Link
                >.
              </ClickToCall>
            </AccordionPanel>
          </div>
        </div>
      </div>

      <div class="space-y-8 md:space-y-0 md:flex md:space-x-8">
        <div class="box-content w-6 h-6 p-3 bg-darkBlue text-white rounded-md">
          <Icon i-heroicons-outline:shield-check />
        </div>
        <div class="flex-1 space-y-8">
          <h2 class="text-3xl md:text-4xl">
            2. We will match you with a Medicare10 advocate
          </h2>
          <div>
            <AccordionPanel title="What is a Medicare10 advocate?">
              Our Medicare10 advocates are licensed insurance agents who have
              been helping people in your community with their healthcare
              coverage. They represent multiple healthcare providers, so they
              can help find the best options for you.
            </AccordionPanel>
            <AccordionPanel
              title="How will the Medicare advocate know what plans I am eligible for?"
            >
              Our advocate team has access to world-class software, which
              compares many healthcare plans and healthcare carriers at once.
              This gives you an excellent opportunity to find a plan with the
              right benefits in the right budget.
            </AccordionPanel>
            <AccordionPanel
              title="What makes a Medicare10 advocate different from any other insurance agent?"
            >
              They care! Our mission is to improve healthcare outcomes for every
              person we speak with. We want to fit the healthcare plan to your
              unique situation so you can have the best options for your future.
            </AccordionPanel>
          </div>
        </div>
      </div>

      <div class="space-y-8 md:space-y-0 md:flex md:space-x-8">
        <div class="box-content w-6 h-6 p-3 bg-darkBlue text-white rounded-md">
          <Icon i-heroicons-outline:user-group />
        </div>
        <div class="flex-1 space-y-8">
          <h2 class="text-3xl md:text-4xl">
            3. Join the Medicare10 revolution
          </h2>
          <div>
            <AccordionPanel title="What is the Medicare10 revolution?">
              We are obsessed with improving healthcare outcomes and that does
              not stop with finding you the right healthcare plan. We see way
              too many people under-utilize their plan and our goal is to change
              that. The revolution is not to just help you pick your coverage,
              but to help you use it, too!
            </AccordionPanel>
            <AccordionPanel title="What if I have questions?">
              Give us a call and we will be happy to answer all of your
              questions. Medicare10 is here for your entire healthcare journey,
              and if you have a question on a plan benefit or if you need to
              update coverage, we want to be there to support you. Just give us
              a call at
              <ClickToCall v-slot="{ handleClick }: any">
                <Link :to="phone.uri" @click="handleClick">
                  {{ phone.formatted }} </Link
                >!
              </ClickToCall>
            </AccordionPanel>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "smid": "MULTIPLAN_EHWEB0819v1_M",
    "robots": "noindex"
  }
}
</route>
