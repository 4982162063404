interface TrustedForm {
  token: null | string
  certUrl: null | string
  pingUrl: null | string
}

export const trustedForm: TrustedForm = reactive({
  token: null,
  certUrl: null,
  pingUrl: null,
})

const getTrustedFormData = () => {
  if (document.querySelector('input[name="xxTrustedFormToken"]')) {
    trustedForm.token = (
      document.querySelector(
        'input[name="xxTrustedFormToken"]'
      ) as HTMLInputElement
    ).value

    trustedForm.certUrl = (
      document.querySelector(
        'input[name="xxTrustedFormCertUrl"]'
      ) as HTMLInputElement
    ).value

    trustedForm.pingUrl = (
      document.querySelector(
        'input[name="xxTrustedFormPingUrl"]'
      ) as HTMLInputElement
    ).value
  }
}

onInit(({ isClient }) => {
  if (isClient) {
    getTrustedFormData()
    useMutationObserver(document.body, getTrustedFormData, {
      subtree: true,
      childList: true,
      attributes: true,
    })
  }
})
