<script setup lang="ts">
import { Product } from '~/modules/product'

interface Step {
  id: string
  skip: boolean
  available: boolean
  index: number
}

const emit = defineEmits<{
  (e: 'stepChange', step: Step, oldStep?: Step): void
  (e: 'stepSubmit', step: Record<string, any>): void
}>()

const props = defineProps<{
  id: string
  steps: {
    id: string
    if?: boolean
    skip?: boolean
  }[]
  product: Product
  scrollBehavior?: 'scrollToTop' | 'keepPos' | 'auto'
  successPage?: string
  failurePage?: string
  submitHandler?: 'leadgen' | (() => Promise<void>)
}>()

const instanceId = uuid()

const windowScroll = useWindowScroll()

const leadgenRequests = useLocalStorage<any[]>('leadgenRequests', [])

const router = useRouter()
const route = useRoute()

const steps = computed<Step[]>(() => {
  return props.steps.map((step, index) => {
    return {
      id: step.id,
      skip: !!step.skip,
      available: step.if !== false,
      index,
    }
  })
})

const routeName = _.findLast(route.matched, (route) => !!route.children.length)!
  .name!

const currStepId = computed(() => {
  return _.nth(route.matched, -2)?.name === routeName
    ? route.path.match(/([^\/]+)\/?$/)![1]
    : null
})

const currStep = computed(() => {
  return steps.value.find((step) => step.id === currStepId.value)
})

const prevStep = computed(() => {
  return (
    currStep.value &&
    steps.value
      .slice(0, currStep.value.index)
      .reverse()
      .find((step) => step.available)
  )
})

const nextStep = computed(() => {
  return currStep.value
    ? steps.value.slice(currStep.value.index + 1).find((step) => step.available)
    : steps.value.find((step) => step.available)
})

const goTo = (step: Step, keepParams = true) => {
  if (currStep.value) {
    router.push({
      path: route.path.replace(/[^\/]+\/?$/, step.id),
      query: keepParams ? route.query : {},
    })
  } else {
    router.replace({
      path: `${route.path}/${step.id}`.replace('//', '/'),
      query: keepParams ? route.query : {},
    })
  }
}

if (!currStep.value) goTo(nextStep.value!)

const completed = computed(() => {
  return steps.value
    .slice(0, currStep.value?.index || 0)
    .filter((step) => step.available).length
})

const remaining = computed(() => {
  return currStep.value
    ? 1 +
        steps.value
          .slice(currStep.value.index + 1)
          .filter((step) => step.available).length
    : steps.value.filter((step) => step.available).length
})

const total = computed(() => {
  return completed.value + remaining.value
})

const stepInstanceId = ref(uuid())
const gptMessage = ref<string | null>(null)

watch(currStepId, () => {
  stepInstanceId.value = uuid()
  gptMessage.value = null
})

let skipped = false

const { values, handleSubmit } = useForm()

const onSubmit = handleSubmit(async () => {
  const data = _.omitBy(values, (value, key) => key.startsWith('_'))

  formsRef.value = {
    ...formsRef.value,
    [props.id]: {
      ...formsRef.value[props.id],
      ...data,
    },
  }

  if ('haveMedicaid' in data) {
    setRingPoolUserData({
      dsnp: data.haveMedicaid ? 1 : 0,
    })
  }

  if (typeof data.zipcode === 'string' && /^[0-9]{5}$/.test(data.zipcode)) {
    setRingPoolUserData({
      zip: data.zipcode,
    })
  }

  analytics.track('formStepSubmitted', {
    formVersion: props.id,
    product: props.product,
    stepName: currStepId.value,
    stepNumber: completed.value + 1,
    data,
  })

  const stepSubmitPayload = {
    id: currStepId.value as string,
    number: completed.value + 1,
    values: JSON.parse(JSON.stringify(values)),
  }

  emit('stepSubmit', stepSubmitPayload)

  const keepParams = skipped

  skipped = false

  if (values._stay) return

  await nextTick()

  if (nextStep.value) {
    goTo(nextStep.value, keepParams)
    watchOnce(stepInstanceId, (newStepInstanceId) => {
      generateGptMessage(stepSubmitPayload)
        .then((msg) => {
          if (stepInstanceId.value === newStepInstanceId) {
            gptMessage.value = msg
          }
        })
        .catch(() => {})
    })
  } else if (props.submitHandler === 'leadgen') {
    const data: any = {
      requestId: uuid(),
      leadtype: 'form',
      leadcost: 0,
      city: ip.lookup?.cityName,
      statecode: ip.lookup?.stateCode,
      subid1: query.utm_source,
      subid2: query.utm_medium,
      subid3: query.utm_campaign,
      subid4: query.utm_content,
      subid5: query.utm_term,
      subid6: query.gclid || query.msclkid || query.token,
      subid7: query.fbclid,
      campaign: query.utm_campaign,
      session_id: getSession().id,
      ga_client_id: ga.clientId,
      anonymousId: segment.anonymousId,
      trustedForm: trustedForm.certUrl,
      product: props.product,
      ip: ip.lookup?.value,
      ...forms[props.id],
      ..._.mapKeys(query, (value, key) => `url_${key}`),
    }

    if (data.email && /^\s+$/.test(data.email)) data.email = ''

    if (
      !data.dateofbirth &&
      /^\d{2}\/\d{2}\/\d{4}$/.test(String(query.dateofbirth))
    ) {
      const y = query.dateofbirth!.slice(6)
      const m = query.dateofbirth!.slice(0, 2)
      const d = query.dateofbirth!.slice(3, 5)

      data.dateofbirth = `${y}-${m}-${d}`
    }

    if (!data.ip) Sentry.captureMessage('Missing ip')
    if (!data.statecode) Sentry.captureMessage('Missing statecode')
    if (!data.anonymousId) Sentry.captureMessage('Missing anonymousId')
    if (!data.trustedForm) Sentry.captureMessage('Missing trustedForm')
    if (!data.ga_client_id) Sentry.captureMessage('Missing ga_client_id')

    if (!document.querySelector('input[name="xxTrustedFormCertUrl"]')) {
      Sentry.captureMessage('Missing input[name="xxTrustedFormCertUrl"]')
    }

    const source = query.utm_source?.toLowerCase()
    const medium = query.utm_medium?.toLowerCase()

    const config = {
      headers: {
        accesstoken:
          source === 'google'
            ? 'DxlVLoY4hkGo+sndnkSndg=='
            : source === 'bing'
            ? '+OVNzn8D6EuhTryvaeMq+Q=='
            : source === 'facebook'
            ? 'dv/kHTP3DEOSWetv1lsH7Q=='
            : source === 'ei'
            ? '3lj70Niw+0WGsea1yzoFzw=='
            : source === 'email' || medium === 'email'
            ? 'N7jp5HWDOU2xp6FxnENSUw=='
            : source === 'apx' || source === 'ma2'
            ? 'Vy4XRqr8OkuHhCa5wWiHWA=='
            : source === 'ap'
            ? 'XUnXUAcIT0+4NhEmHXffHw=='
            : source === 'hc'
            ? '+hvjZ+4D0kqotE66Jzn8Lg=='
            : source === 'slqt'
            ? 'k8Mi+ojjXk+ORsvx+x9UmA=='
            : source === 'eh'
            ? 'phcGcL7rg0yHZmuRnpmYRQ=='
            : brand.id === 'eh'
            ? 'phcGcL7rg0yHZmuRnpmYRQ=='
            : brand.id === 'ayb'
            ? 'GXc6ZPlVGkGue9neDmEaZw=='
            : brand.id === 'bh'
            ? 'GXc6ZPlVGkGue9neDmEaZw=='
            : 'GjWz79RXnQE8hR8vU3rj3TkEaNt3Yr4RRC96Ytvn',
      },
    }

    leadgenRequests.value = [
      ...leadgenRequests.value,
      {
        data,
        config,
        date: Date.now(),
        formId: props.id,
      },
    ]

    try {
      const res = await axios.post(
        import.meta.env.VITE_LEADGEN_URL,
        data,
        config
      )

      leadgenRequests.value = leadgenRequests.value.map((request) => {
        return request.data.requestId === data.requestId
          ? {
              ...request,
              response: {
                data: res.data,
                status: res.status,
              },
            }
          : request
      })

      analytics.track('formSubmitted', {
        formVersion: props.id,
        product: props.product,
        requestId: data.requestId,
        leadId: res.data.lead_id,
        userId: res.data.user_id,
      })

      if (props.successPage) {
        router.push(props.successPage)
      }
    } catch (err: any) {
      const response = err.response
      const responseData = response?.data

      const code = responseData?.code
      const reason =
        code === '0648'
          ? 'Duplicate'
          : code === '0650'
          ? 'DNC'
          : code === '0657'
          ? 'No TrustedForm'
          : null

      leadgenRequests.value = leadgenRequests.value.map((request) => {
        return request.data.requestId === data.requestId
          ? {
              ...request,
              ...(response
                ? {
                    response: {
                      data: responseData,
                      status: response.status,
                    },
                  }
                : null),
            }
          : request
      })

      if (reason) {
        Sentry.captureMessage(
          `${reason} lead`,
          reason === 'No TrustedForm' ? 'error' : undefined
        )
      } else {
        Sentry.captureException(err, {
          contexts: {
            request: {
              data,
              config,
            },
            ...(response ? { response: { data: responseData } } : null),
          },
        })
      }

      if (response) {
        analytics.track('formRejected', {
          formVersion: props.id,
          product: props.product,
          requestId: data.requestId,
          response: {
            data: responseData,
          },
          reason,
        })
      }

      if (props.failurePage) {
        router.push(props.failurePage)
      }
    }
  } else if (props.submitHandler) {
    await props.submitHandler()
  }
})

watch(
  currStepId,
  async (stepId, oldStepId) => {
    const step = steps.value.find((step) => step.id === stepId)
    const oldStep = steps.value.find((step) => step.id === oldStepId)

    if (step) {
      emit('stepChange', step, oldStep)

      await nextTick()
      await nextTick()

      if (!import.meta.env.SSR && props.scrollBehavior === 'keepPos') {
        window.scrollTo({ top: windowScroll.y.value })
      }

      if (!import.meta.env.SSR && props.scrollBehavior === 'auto' && oldStep) {
        const header = document.querySelector('.sticky.top-0')
        const headerHeight = header ? header.getBoundingClientRect().height : 0

        const rect = document.getElementById('form')!.getBoundingClientRect()
        const elTop = window.scrollY + rect.top - headerHeight
        const elBottom = window.scrollY + rect.bottom - headerHeight
        const elHeight = rect.height
        const viewportHeight = window.innerHeight - headerHeight
        const offset =
          elHeight > viewportHeight ? elTop : elBottom - viewportHeight

        window.scrollTo({ top: windowScroll.y.value })
        setTimeout(() => {
          window.scrollTo({ top: offset, behavior: 'smooth' })
        }, 100)
      }

      if (!import.meta.env.SSR && window.innerWidth >= 768) {
        document.getElementById('form')!.querySelector('input')?.focus()
      }

      if (
        step.skip &&
        step.index > (oldStep?.index ?? -1) &&
        _.every(values, Boolean)
      ) {
        skipped = true
        onSubmit()
      } else {
        analytics.page({
          name: `Form ${props.id} Step ${step.id}`,
          category: 'Form',
          product: props.product,
          stepNumber: completed.value + 1,
        })
      }
    }
  },
  { immediate: true }
)

provide('form', {
  id: props.id,
  steps,
  currStep,
  prevStep,
  nextStep,
  goTo,
  completed,
  remaining,
  total,
  onSubmit,
  instanceId,
  gptMessage,
})

const useFormData: any = inject('useFormData', null)

if (useFormData) {
  useFormData.id = computed(() => props.id)
  useFormData.values = computed(() => forms[props.id])
  useFormData.product = computed(() => props.product)
}
</script>

<template>
  <form @submit="onSubmit" id="form" class="max-w-xl mx-auto space-y-6">
    <RouterView />
    <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
  </form>
</template>
