<script setup lang="ts">
const props = defineProps<{
  to: string
}>()

const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`${props.to}/?zip=${values.zip}`)
})
</script>

<template>
  <form
    @submit="onSubmit"
    class="grid grid-cols-[repeat(auto-fit,minmax(15.5rem,1fr))] items-start gap-4"
  >
    <Field
      type="zip"
      name="zip"
      rules="required"
      placeholder="Enter ZIP Code"
      class="text-center"
    />
    <Button wide type="submit" class="whitespace-nowrap">
      Continue
    </Button>
  </form>
</template>
