<script setup lang="ts">
withDefaults(
  defineProps<{
    textColor?: string
    barColor?: string
  }>(),
  {
    textColor: 'text-darkBlue',
    barColor: 'bg-darkBlue',
  }
)

const { instanceId, completed, total } = inject<any>('form')

const nFinal = Math.round(((completed.value + 1) / (total.value + 1)) * 100)

const n = useSessionStorage(`form.${instanceId}.n`, 0)

onMounted(() => {
  setTimeout(() => {
    n.value = nFinal
  }, 0)
})
</script>

<template>
  <div class="space-y-2 mb-12">
    <div :class="['text-xl sm:text-2xl', textColor]">
      <span class="font-medium"> {{ nFinal + '%' }} </span> complete
    </div>
    <div class="bg-gray-300 rounded-full overflow-hidden">
      <div
        :class="[
          'h-1.5 rounded-full transition-all duration-300 ease-linear',
          barColor,
        ]"
        :style="{ width: `${n}%` }"
      >
      </div>
    </div>
  </div>
</template>
