<script setup lang="ts">
const { onSubmit } = inject('form') as any

const firstname = ref()
const lastname = ref()

const skip = () => {
  firstname.value.value = ''
  lastname.value.value = ''
  onSubmit()
}
</script>

<template>
  <FormProgress />

  <FormTitle> What's your full name? </FormTitle>

  <Field
    ref="firstname"
    name="firstname"
    label="First name"
    autocomplete="given-name"
    :initialValue="query.firstname"
  />

  <Field
    ref="lastname"
    name="lastname"
    label="Last name"
    autocomplete="family-name"
    :initialValue="query.lastname"
  />

  <FormButtonNext />

  <Button wide size="sm" variant="gray" @click="skip"> Skip </Button>
</template>
