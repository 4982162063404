<script setup lang="ts">
defineProps<{
  partBPremium?: string
}>()

const hostname = ref<string>()

onMounted(() => {
  hostname.value = window.location.hostname
})
</script>

<template>
  <div class="w-full bg-[#060519] text-white">
    <div class="container py-12 flex gap-12">
      <div class="flex flex-col gap-4">
        <RouterLink to="/">
          <img src="../assets/logo-pc-white.svg" />
        </RouterLink>
        <div class="pl-16">A Better Way to Care</div>
      </div>
      <div class="flex flex-col gap-4">
        <Button
          :to="`/tos`"
          variant="transparent"
          size="sx"
          class="!justify-start !py-1"
          >Terms of Service</Button
        >
        <Button
          :to="`/privacy`"
          variant="transparent"
          size="sx"
          class="!justify-start !py-1"
          >Privacy Policy</Button
        >
      </div>

      <div class="flex flex-col gap-4">
        <Button
          :to="`/articles`"
          variant="transparent"
          size="sx"
          class="!justify-start !py-1"
          >Articles</Button
        >
        <Button
          :to="`/paap/find-a-provider`"
          variant="transparent"
          size="sx"
          class="!justify-start !py-1"
          >Find a Provider</Button
        >
      </div>
      <div class="text-white/40 h-20 flex items-end"
        >Copyright © 2023 ValueCare Seniors. All rights reserved.</div
      >
    </div>
  </div>
</template>
