<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Countdown 2024 clock - ${brand.name}`,
})

const aepEnd = DateTime.fromISO('2023-12-08T12:00:00Z')
const duration = ref<Record<string, string>>()

onMounted(() => {
  useIntervalFn(
    () => {
      duration.value = _.mapValues(
        aepEnd
          .diffNow()
          .shiftTo('days', 'hours', 'minutes', 'seconds')
          .toObject(),
        (value) => value!.toFixed()
      )
    },
    500,
    {
      immediateCallback: true,
    }
  )
})
</script>

<template>
  <Layout header="simple3" footer="buyback2024" class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Have Medicare? Turning 65? Some Americans with Medicare A&B in
        {{ ip.lookup?.stateName || 'America' }} are getting
        <span class="text-red">
          extra benefits like dental, vision, or money back*
        </span>
        on their social security with some Medicare Advantage Plans.
      </div>

      <div class="sm:w-[500px] mx-auto bg-yellow rounded-md p-6">
        <div class="text-center text-xl font-medium"> Lorem ipsum </div>
        <div class="text-center mt-1 mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </div>
        <div class="flex justify-evenly text-center max-w-[400px] mx-auto">
          <div>
            <div class="text-2xl font-medium bg-orange py-1 px-2 rounded-md">
              {{ duration?.days }}
            </div>
            <div class="mt-1 uppercase tracking-wide text-xs"> Days </div>
          </div>
          <div>
            <div class="text-2xl font-medium bg-orange py-1 px-2 rounded-md">
              {{ duration?.hours }}
            </div>
            <div class="mt-1 uppercase tracking-wide text-xs"> Hours </div>
          </div>
          <div>
            <div class="text-2xl font-medium bg-orange py-1 px-2 rounded-md">
              {{ duration?.minutes }}
            </div>
            <div class="mt-1 uppercase tracking-wide text-xs"> Minutes </div>
          </div>
          <div>
            <div class="text-2xl font-medium bg-orange py-1 px-2 rounded-md">
              {{ duration?.seconds }}
            </div>
            <div class="mt-1 uppercase tracking-wide text-xs"> Seconds </div>
          </div>
        </div>
      </div>

      <UseForm v-slot="{ values }">
        <Form
          id="countdown2024Clock"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'sorry', if: false },
            { id: 'medicaid' },
            { id: 'us' },
            { id: 'loading' },
            { id: 'call' },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "smid": "MULTI-PLAN_EHWEB062301_M",
    "robots": "noindex"
  }
}
</route>
