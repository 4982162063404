<script setup lang="ts">
import { Product } from '~/modules/product'

interface UseFormData {
  id?: string
  values?: Record<string, any>
  product?: Product
}

const data: UseFormData = reactive({})

provide('useFormData', data)
</script>

<template>
  <slot v-bind="data" />
</template>
