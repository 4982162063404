interface Query {
  [key: string]: string | null | undefined
}

const queryRef = useLocalStorage<Query>('url_query_params', {})

export const query: Query = toReactive(queryRef)

watch(
  () => queryRef.value.data,
  () => {
    const data = queryRef.value.data
    if (data) {
      axios
        .post(
          import.meta.env.VITE_EH_INTERNAL_API_URL + '/mediaalpha/decrypt',
          {
            data,
          },
          {
            headers: {
              'x-api-key': import.meta.env.VITE_EH_INTERNAL_API_KEY,
            },
          }
        )
        .then((res) => {
          const queryString = res.data.data
          const searchParams = new URLSearchParams(queryString)
          const decrypted = _.omitBy(
            _.fromPairs(Array.from(searchParams.entries())),
            _.isEmpty
          )

          queryRef.value = { ...queryRef.value, ...decrypted }
        })
        .catch(() => {})
    }
  }
)

onInit(({ router, isClient }) => {
  router.beforeEach((to) => {
    const routeQuery: Query = _.mapValues(to.query, (value) => {
      return _.isArray(value) ? _.last(value) : value
    })

    if (routeQuery.exp) {
      const experiments = routeQuery.exp.split(',')

      experiments.forEach((experiment) => {
        const [id, variant] = experiment.split('@')

        if (id && variant) {
          forceEnterExperiment(id, variant)
        }
      })
    }

    queryRef.value = { ...queryRef.value, ...routeQuery }

    if (isClient) {
      const session = getSession()
      const key = `url_query_params_session_${session.id}`
      window.localStorage.setItem(
        key,
        JSON.stringify({
          ...JSON.parse(String(window.localStorage.getItem(key))),
          ...routeQuery,
        })
      )
    }

    const routeQueryWithoutPii = _.omit(routeQuery, [
      'firstname',
      'lastname',
      'age',
      'phone',
      'dateofbirth',
      'device',
      'devicetype',
      'session_id',
    ])

    if (!_.isEqual(to.query, routeQueryWithoutPii)) {
      return { ...to, query: routeQueryWithoutPii }
    }
  })
})
