<script setup lang="ts">
import carouselImg1 from '../../assets/landing-page-eh-carousel-img1.png'
import carouselImg2 from '../../assets/landing-page-eh-carousel-img2.png'
import carouselImg3 from '../../assets/landing-page-eh-carousel-img3.png'

useHead({
  title: 'EasyHealth.com',
})

analytics.page({
  name: 'EasyHealth.com Coming Soon',
  category: 'HP',
})

const items = [
  {
    img: carouselImg1,
    title: 'Tech Enabled',
    text: 'Smart health insurance is built on technology. Our data-driven platform powers leading agencies and health plans.',
  },
  {
    img: carouselImg2,
    title: 'Member First',
    text: 'We built the first end-to-end Medicare experience because healthcare is complicated, and we wanted to make it easy.',
  },
  {
    img: carouselImg3,
    title: 'Mission Driven',
    text: 'We created EasyHealth to improve health outcomes. We are committed to solving important health problems.',
  },
]
</script>

<template>
  <Layout>
    <header
      class="pb-4 pt-8 container flex items-center justify-center flex-col gap-4"
    >
      <img
        src="../../assets/emily-avatar.png"
        alt="Emily Bot Avatar"
        class="w-36 h-36 rounded-full animate-slideInBottom"
      />
      <h1
        class="font-bold text-3xl mb-2 px-2 animate-slideInBottom text-center"
        style="animation-delay: 400ms"
      >
        Hi, I'm Emily from EasyHealth
      </h1>
      <h2
        class="text-3xl px-2 animate-slideInBottom text-center"
        style="animation-delay: 800ms"
      >
        I help people make better health choices.
      </h2>
    </header>
    <section
      class="container py-8 animate-slideInBottom"
      style="animation-delay: 1200ms"
    >
      <div class="flex flex-wrap items-center justify-center -m-2">
        <Button
          to="https://benefit-helpline.com/aca/open-enrollment/healthInsurance"
          variant="yellow"
          class="m-2"
        >
          Health Insurance Plans
        </Button>
        <Button
          to="https://shop.medicare10.com/call-4-extra-benefits/medicare"
          variant="yellow"
          class="m-2"
        >
          Medicare Plans
        </Button>
      </div>
    </section>
    <section
      class="container flex flex-wrap py-8 animate-slideInBottom"
      style="animation-delay: 1.6s"
    >
      <Carousel :items="items" />
    </section>
    <section
      class="flex flex-wrap py-8 bg-gray-200 animate-slideInBottom"
      style="animation-delay: 1.6s"
    >
      <div class="container flex flex-col">
        <div class="flex flex-row flex-grow gap-4 md:flex-row flex-col">
          <div class="flex flex-col flex-grow gap-4">
            <h3
              class="text-3xl md:text-6xl text-left max-w-[640px] leading-tight py-4"
            >
              Get to Know More About EasyHealth
            </h3>
            <p class="py-4 text-xl max-w-[730px]">
              Our one track mind is always focused on finding different ways to
              improve medical outcomes. We have come a long way from selling
              insurance, to selling impact.
            </p>
            <p class="mb-8 text-2xl">
              Discover better healthcare with EasyHealth.
            </p>
          </div>

          <div class="flex flex-grow-0">
            <img
              src="../../assets/landing-page-eh-more-about.png"
              class="md:max-w-[480px] max-w-full"
            />
          </div>
        </div>
        <div class="md:grid md:grid-cols-2 flex flex-col gap-4">
          <Button
            variant="yellow"
            size="sm"
            to="https://benefit-helpline.com/aca/open-enrollment/healthInsurance"
            style="animation-delay: 1.2s"
          >
            Health Insurance Plans
          </Button>
          <Button
            variant="yellow"
            size="sm"
            to="https://shop.medicare10.com/call-4-extra-benefits/medicare"
            style="animation-delay: 1.2s"
          >
            Medicare Plans
          </Button>
        </div>
      </div>
    </section>
  </Layout>
</template>
