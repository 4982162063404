import { DateTime } from 'luxon'

type PhoneConfig =
  | string
  | {
      placementId: string
      default: string
      afid?: string
    }
  | {
      replacementNumber: string
      campaignId: string
    }

interface Phone {
  value: string
  formatted: string
  uri: string
}

interface RingPoolNumber {
  [key: string]:
    | undefined
    | {
        value: string | null
        expirationDate: number | null
      }
}

const globalPhone = (language: string): PhoneConfig => {
  const source = query.utm_source?.toLowerCase()
  const medium = query.utm_medium?.toLowerCase()
  const product = query.product?.toLowerCase()
  // const campaign = query.utm_campaign?.toLowerCase()

  const pc = brand.id === 'pc'
  const bh = brand.id === 'bh'
  const m10 = brand.id === 'm10'
  const ayb = brand.id === 'ayb'
  const med10 = brand.id === 'med10'
  const boomer = brand.id === 'boomer'

  const notProd = import.meta.env.MODE !== 'production'

  switch (true) {
    case notProd:
      return {
        placementId: 'Kcyq_Ynw0sN5cKLLw-pE1kpwUXyP3g',
        default: '18557651071',
        afid: '000000',
      }

    case bh && source === 'tbl':
      return {
        placementId: 'fdXBT-m7QIcWixdJJLtcejtU8fDi0A',
        default: '18334350573',
      }

    case bh && source === 'facebook' && product === 'aca':
      return {
        placementId: '5yPWl0ZJR7tCEFwEPdgci3zwLhRJhw',
        default: '18449372025',
        afid: '811040',
      }

    case bh && source === 'facebook':
      return {
        placementId: 'syUSWk0fIpgPGUU38LH3M_BXhlmupg',
        default: '18337131570',
        afid: '810984',
      }

    case bh && source === 'google' && medium === 'search':
      return {
        placementId: 'MbRbcqgCrAJt7dOnx5TaGi1l-arRIw',
        default: '18882945785',
        afid: '811038',
      }

    case bh && source === 'google' && medium === 'youtube':
      return {
        placementId: 'Wy03EiLuxQBPf0Tg-9psFOqnm1E-PQ',
        default: '18446523014',
      }

    case bh && source === 'google':
      return {
        placementId: 'pOabRc17MPjlFfu3hoYVDorx6-TIaA',
        default: '18444090332',
        afid: '811025',
      }

    case bh && source === 'sf':
      return {
        placementId: 'VeXUKzqHr4u_WF2uX_0jmIplU3z_Pg',
        default: '18557126185',
      }

    case bh && source === 'qst':
      return {
        placementId: 'G1O84yLyOwnusQwTij3xxa6fdMnaRg',
        default: '18886136252',
        afid: '811251',
      }

    case bh && source === 'tiktok' && product === 'aca':
      return {
        placementId: 'wWj7sxRwLB1qxRXjdmg0HCHbHyUjxg',
        default: '18446361799',
        afid: '811265',
      }

    case bh && source === 'tiktok':
      return {
        placementId: 'gkqOF21gm2CLZ0UNNaQdSmDqGZQQeg',
        default: '18559510581',
        afid: '811264',
      }

    case bh && source === 'maox':
      return {
        placementId: 'voFvGZ3s_5s1UkJDJQqw1PCD3Fq_Dw',
        default: '18669944962',
        afid: '811260',
      }

    case bh && medium === 'aff':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18665615698',
        afid: '811241',
      }

    case bh:
      return {
        placementId: 'qJJD7vjIH43fBjnh2BIZNqL0hqB-Aw',
        default: '18336221595',
        afid: '811217',
      }

      case ayb && source === 'google' && product === 'aca':
        return {
          placementId: 'av9FTtNbrgnn0tVESLCRKYMhE8cqhg',
          default: '8885056293',
          afid: '811074',
        }

    case ayb && source === 'qst' && product === 'aca':
      return {
        placementId: 'l67O5RnsJylnVtFQboHlXo5DkG-_UQ',
        default: '8556400557',
        afid: '811267',
      }

    case ayb && source === 'apl' && product === 'aca':
      return {
        placementId: '0yAloQDlvq_qbnkretTRUATFnXmKIw',
        default: '8556320431',
        afid: '811270',
      }

    case ayb && source === 'eipx' && product === 'aca':
      return {
        placementId: 'aW4IKSarkYcUGlDNR2gec2OS_gHjKA',
        default: '8775617248',
        afid: '811271',
      }

    case ayb && source === 'apx' && product === 'aca':
      return {
        placementId: 'As6N7-ZfaiTO0ytObaXaUTIWOZCymA',
        default: '8553305304',
        afid: '811268',
      }

    case ayb && source === 'maox' && product === 'aca':
      return {
        placementId: 'W4FcsNBxGjT8qskqAERwi-vE5BzFeA',
        default: '8778396544',
        afid: '811269',
      }

    case ayb && medium === 'aff':
      return {
        placementId: 'asNCSXuD3butGjTdB6rPsIo4C5z6ZQ',
        default: '18559423622',
        afid: '811253',
      }

    case ayb:
      return {
        placementId: 'xsrTZdV32KSmi_iL3uOBL0X2KKMsIQ',
        default: '18449924248',
        afid: '811070',
      }

    case m10 && source === 'apl':
      return {
        placementId: 'uE1GAyTxhdzoSUYvsywFAgdlnyz1HQ',
        default: '18337172060',
        afid: '811165',
      }

    case m10 && source === 'eipx':
      return {
        placementId: '7tNdiU83ILiv-uKbXMljEpyIU3INgQ',
        default: '18339781905',
        afid: '811166',
      }

    case m10 && source === 'lgl':
      return {
        placementId: 'kRNgUtoLon1gsiGlfwxqzBXQZ9hhnA',
        default: '18776403822',
        afid: '811069',
      }

    case m10 && source === 'tbl':
      return {
        placementId: 'fdXBT-m7QIcWixdJJLtcejtU8fDi0A',
        default: '18334350573',
        afid: '811066',
      }

    case m10 && source === 'czl':
      return {
        placementId: 'd15wiDQfiZwZwf5R_7b9peSNrj_gcA',
        default: '18559154271',
        afid: '811169',
      }

    case m10 && source === 'obn':
      return {
        placementId: 'Rsd4V04Me7so-AyOASiDxRUoMkvexg',
        default: '18336450534',
        afid: '811170',
      }

    case m10 && source === 'md':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18333497540',
        afid: '811250',
      }

    case m10 && source === 'apx' && medium === 't1':
      return {
        placementId: '4VqJZE58638sBV2s8vfbRsmEp1xAgQ',
        default: '18449761309',
        afid: '811055',
      }

    case m10 && source === 'apx' && medium === 't2':
      return {
        placementId: '37_Kx6aWXe1LKUY5x0SaRdpsB8QegQ',
        default: '18775655585',
        afid: '811056',
      }

    case m10 && source === 'apx':
      return {
        placementId: 'NgnnAgsTLWO0o6aTSqlXJAw-QHjesw',
        default: '18447051812',
        afid: '811056',
      }

    case m10 && source === 'maox':
      return {
        placementId: 'voFvGZ3s_5s1UkJDJQqw1PCD3Fq_Dw',
        default: '18669944962',
        afid: '811260',
      }

    case m10 && source === 'sf':
      return {
        placementId: 'VeXUKzqHr4u_WF2uX_0jmIplU3z_Pg',
        default: '18557126185',
      }

    case m10 && source === 'google' && medium === 'youtube':
      return {
        placementId: 'Wy03EiLuxQBPf0Tg-9psFOqnm1E-PQ',
        default: '18446523014',
      }

    case m10 && source === 'google':
      return {
        placementId: 'pOabRc17MPjlFfu3hoYVDorx6-TIaA',
        default: '18444090332',
        afid: '811025',
      }

    case m10 && source === 'facebook':
      return {
        placementId: 'GdH2gFR8XJzdcs1HpyXL4WfqVgzwkw',
        default: '18773895135',
        afid: '810989',
      }

    case m10 && source === 'eh':
      return {
        placementId: '0yZ9ZrsiLUNI3L5H7ioqwYoah9urag',
        default: '18339311756',
      }

    case m10 && medium === 'aff':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18665615698',
        afid: '811241',
      }

    case m10:
      return {
        placementId: 'JHqkls-LrwvXE8Hd-ZMf7ijddY7wVw',
        default: '18335732845',
        afid: '810983',
      }

    case boomer && source === 'facebook':
      return {
        placementId: 'f_UbB19O1WFfBGpaQFEgxs5hAp0w2g',
        default: '18444901521',
        afid: '811019',
      }

    case boomer:
      return {
        placementId: 'sm0Eh2fHarV8_KkPoTAysNfkNr11NA',
        default: '18666967616',
        afid: '811245',
      }

    case pc:
      return {
        placementId: '47RueOdUPt-mYeQVZQwZRSxECHSOsw',
        default: '18444314200',
        afid: '811239',
      }

    case med10 && source === 'maox':
      return {
        placementId: 'voFvGZ3s_5s1UkJDJQqw1PCD3Fq_Dw',
        default: '18669944962',
        afid: '811260',
      }

      case med10 && source === 'facebook':
        return {
          placementId: 'hPiCQFF77bBPizar31iJ0ujaAn6CzQ',
          default: '18777301943',
          afid: '811051',
        }

    case med10:
      return {
        placementId: 'ubc3ytI-mt_08U4B_xUEdZctOtNwXQ',
        default: '18666968144',
        afid: '811244',
      }

    default:
      return brand.phone
  }
}

export const phoneConfig = ref<PhoneConfig>()

export const placementId = computed(() => {
  return typeof phoneConfig.value === 'object' &&
    'placementId' in phoneConfig.value
    ? phoneConfig.value.placementId
    : null
})

const campaignId = computed(() => {
  return typeof phoneConfig.value === 'object' &&
    'campaignId' in phoneConfig.value
    ? phoneConfig.value.campaignId
    : null
})

const dynamicPhoneConfig = ref<PhoneConfig | null>()

export const usePhone = (value: PhoneConfig) => {
  dynamicPhoneConfig.value = value

  onBeforeUnmount(() => {
    dynamicPhoneConfig.value = null
  })
}

const ringPoolNumber = ref<RingPoolNumber>({})

const onGetRingPoolNumberFail = _.once((payload: any) => {
  Sentry.captureMessage('Failed to get MA ring pool number', {
    level: 'error',
    extra: {
      ...payload,
    },
  })
})

const getRingPoolNumber = (payload: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    until(maLoaded)
      .toBe(true)
      .then(() => {
        window.MediaAlphaExchange = payload
        window.MediaAlphaExchange__loadIVRPool((phone_num: any) => {
          if (phone_num) {
            resolve({
              phone_num,
              expiration_time: DateTime.now()
                .plus({ seconds: payload.phone_num_lease_time })
                .toISO(),
            })
          } else {
            onGetRingPoolNumberFail(payload)
            reject()
          }
        })
      })
  })
}

const ringPoolUserData = ref<Record<string, any>>({})

export const setRingPoolUserData = (data: Record<string, any>) => {
  ringPoolUserData.value = {
    ...ringPoolUserData.value,
    ...data,
  }
  ringPoolNumber.value = _.mapValues(ringPoolNumber.value, (value) => {
    if (!value) return value
    return {
      ...value,
      expirationDate: Date.now() + 10 * 1000,
    }
  })
}

watch(placementId, async (pid) => {
  if (pid) {
    if (!ringPoolNumber.value[pid]) {
      ringPoolNumber.value = {
        ...ringPoolNumber.value,
        [pid]: {
          value: null,
          expirationDate: null,
        },
      }

      const afid = (phoneConfig.value as { afid?: string }).afid

      while (true) {
        if (
          !ringPoolNumber.value[pid]!.value ||
          Date.now() > ringPoolNumber.value[pid]!.expirationDate! - 20 * 1000
        ) {
          try {
            await until(() => ip.lookup).toBeTruthy()

            const res = await getRingPoolNumber({
              data:
                query.zip && /^[0-9]{5}$/.test(query.zip)
                  ? {
                      zip: query.zip,
                      ...ringPoolUserData.value,
                    }
                  : ringPoolUserData.value,
              ip: ip.lookup!.value,
              local_hour: new Date().getHours(),
              phone_num_lease_time: 300,
              placement_id: pid,
              ua: navigator.userAgent,
              url: location.href,
              version: 17,
              sub_1: query.afid || afid,
              sub_2: segment.anonymousId,
              sub_3: getSession().id,
              source: [
                query.afid || afid || 'null',
                query.utm_campaign || 'null',
                query.adgroupid || 'null',
                query.gclid ? `gclid=${query.gclid}` : 'null',
                query.publisher || 'null',
                query.channel || 'null',
                query.etid || 'null',
              ],
            })

            ringPoolNumber.value = {
              ...ringPoolNumber.value,
              [pid]: {
                value: res.phone_num.slice(1),
                expirationDate: new Date(res.expiration_time).getTime(),
              },
            }
          } catch (err) {}
        }
        await new Promise((resolve) => setTimeout(resolve, 4000))
      }
    }
  }
})

watch(campaignId, (cid) => {
  if (cid) {
    analytics.ready(() => {
      useScriptTag(`https://b-js.ringba.com/${cid}`)
    })
  }
})

const timestamp = useTimestamp()

export const phone: Phone = reactive({
  value: computed(() => {
    if (typeof phoneConfig.value === 'object') {
      if ('placementId' in phoneConfig.value) {
        const number = ringPoolNumber.value[phoneConfig.value.placementId]
        return number?.value && number.expirationDate! > timestamp.value
          ? number.value
          : phoneConfig.value.default
      }
      return phoneConfig.value.replacementNumber
    }
    return phoneConfig.value || brand.phone
  }),
  formatted: computed(() => {
    const v = phone.value
    return `${v.slice(0, 1)}-${v.slice(1, 4)}-${v.slice(4, 7)}-${v.slice(7)}`
  }),
  uri: computed(() => {
    return `tel:+${phone.value}`
  }),
})

onInit(({ isClient, router }) => {
  if (isClient) {
    router.isReady().then(() => {
      const globalPhoneConfig = globalPhone(
        (router.currentRoute.value.meta.language as string | undefined) ||
          'english'
      )

      const routePhoneConfig = computed(() => {
        return router.currentRoute.value.meta.phone as PhoneConfig | undefined
      })

      watchSyncEffect(() => {
        phoneConfig.value =
          dynamicPhoneConfig.value ||
          routePhoneConfig.value ||
          globalPhoneConfig
      })
    })
  }
})
