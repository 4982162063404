<script setup lang="ts">
import { DateTime } from 'luxon'

const props = defineProps<{
  date: string
  source?: string
}>()

const dateFormatted = DateTime.fromISO(props.date).toFormat('MMM d, y')
</script>

<template>
  <div class="min-h-screen flex flex-col bg-gray-50">
    <Header />
    <div class="flex-1">
      <div class="container">
        <div class="prose prose-gray md:text-lg my-16 mx-auto">
          <slot />
          <hr />
          <div class="flex items-center space-x-3">
            <img src="../../assets/emily-avatar.png" class="w-16 h-16" />
            <div>
              <div class="text-gray-900 text-lg md:text-xl">
                Written by Emily
              </div>
              <div class="text-gray-500"> {{ dateFormatted }} </div>
            </div>
          </div>
          <div class="mt-3 text-base text-gray-500">
            Emily is our smart health assistant. While she is really smart, she
            doesn't know everything, so it's always best to do your own
            research.
            <template v-if="source">
              I read this article when writing this:
              <a :href="source" class="font-normal" target="_blank">{{
                source
              }}</a
              >.
            </template>
          </div>
        </div>
        <div class="h-px bg-gray-200"></div>
      </div>
    </div>
    <Footer />
  </div>
</template>
