import { isValidPhoneNumber } from 'libphonenumber-js'
import isEmail from 'validator/lib/isEmail'
import mailcheck from 'mailcheck'

defineRule('required', (value: string) => {
  return !!value.trim() || 'Required field'
})

defineRule('min', (value: string, [limit]: string[]) => {
  return (
    !value.trim() ||
    value.trim().length >= Number(limit) ||
    `Minimum of ${limit} characters`
  )
})

defineRule('max', (value: string, [limit]: string[]) => {
  return (
    !value.trim() ||
    value.trim().length <= Number(limit) ||
    `Maximum of ${limit} characters`
  )
})

defineRule('zip', (value: string) => {
  return !value || value.length === 5 || 'Invalid ZIP code'
})

defineRule('date', (value: string) => {
  return !value || value.length === 10 || 'Invalid date'
})

defineRule('email', (value: string) => {
  return (
    !value.trim() ||
    (isEmail(value.trim()) && !mailcheck.run({ email: value.trim() })) ||
    'Invalid email'
  )
})

defineRule('phone', (value: string) => {
  return (
    !value ||
    (value.length === 12 && isValidPhoneNumber(value, 'US')) ||
    'Invalid phone number'
  )
})
