<script setup lang="ts">
const { value: medicareab } = useField('medicareab')
</script>

<template>
  <FormProgress />

  <FormTitle> Are you currently enrolled in Medicare Parts A & B? </FormTitle>

  <div class="space-y-3">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="medicareab = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="medicareab = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      @click="medicareab = null"
    >
      Skip
    </Button>
  </div>
</template>
