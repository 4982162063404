<template>
  <div class="min-h-screen flex flex-col bg-gray-50">
    <Header />
    <div class="flex-1">
      <div class="container">
        <div class="prose prose-gray md:text-lg my-24 mx-auto">
          <slot />
        </div>
        <div class="h-px bg-gray-200"></div>
      </div>
    </div>
    <Footer />
  </div>
</template>
