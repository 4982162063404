<script setup lang="ts">
const props = defineProps<{
  stateCode: string
}>()

const imageByCode = _.mapKeys(
  import.meta.glob('../assets/state/*.svg', { eager: true, as: 'url' }),
  (value, key) => key.slice(16, -4)
)

const imageUrl = computed(() => {
  return imageByCode[props.stateCode]
})
</script>

<template>
  <div
    class="aspect-square bg-current"
    :style="{
      maskImage: `url(${imageUrl})`,
      maskSize: '100% 100%',
    }"
  >
  </div>
</template>
