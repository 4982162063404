import NProgress from 'nprogress'

onInit(({ isClient, router }) => {
  if (isClient) {
    router.beforeEach((to, from) => {
      if (to.meta.chunk !== from.meta.chunk) {
        NProgress.start()
      }
    })
    router.afterEach(() => {
      NProgress.done()
    })
  }
})
