<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      We do not offer every plan available in your area. Currently we represent
      over 22 organizations which offer 38 products in your area. Please contact
      Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program
      (SHIP) to get information on all of your options.

      <br />
      <br />

      Not all plans offer all of these benefits. Benefits may vary by carrier
      and location. Limitations and exclusions may apply. Allowance amounts
      cannot be combined with other benefit allowances. Limitations and
      restrictions may apply. The Part B Giveback Benefit plays part or all of
      your Part B premium and the amount may change based on the amount you pay
      for Part B. Part B Premium giveback is not available with all plans.
      Availability varies by carrier and location. Actual Part B premium
      reduction could be lower. The standard Part B premium for
      {{ new Date().getFullYear() }} is $164.90. Monthly savings varies and may
      be subject to processing delays and may not be immediate. Not available
      with all plans. Availability varies by carrier and location.

      <br />
      <br />

      Participating sales agencies represent Medicare Advantage HMO, HMO SNP,
      PPO, PPO SNP and PFFS organizations and stand-alone PDP that have a
      Medicare contract. Enrollment in any plan depends on contract renewal. Not
      all plans offer all of these benefits. Benefits may vary by carrier and
      location. Limitations and exclusions may apply. Calling this number
      <ClickToCall v-slot="{ handleClick }">
        <a :href="phone.uri" @click="handleClick">
          {{ phone.formatted }}
        </a>
      </ClickToCall>
      TTY 711 24/7 will connect you to a licensed insurance agent.

      <br />
      <br />

      The Humana
      <img
        src="../assets/pdp.png"
        width="159"
        height="24"
        class="inline-block align-bottom"
        alt="PDP"
      />
      (PDP) pharmacy network includes limited, lower-cost, preferred pharmacies
      in urban areas of AR, CT, DE, IA, IN, KY, ME, MI, MN, MO, MS, ND, NY, OH,
      PR, RI, SD, TN, VT, WI, WV; suburban areas of CT, HI, MA, ME, MI, MT, ND,
      NJ, NY, OH, PA, PR, RI, WV; and rural areas of IA, MN, MT, ND, NE, SD, VT,
      WY. There are an extremely limited number of preferred cost share
      pharmacies in urban areas in the following states: AR, DE, ME, MI, MN, MS,
      ND, NY, OH, RI, and SD; suburban areas of MT, and ND; and rural areas of
      ND. The lower cost advertised in our plan materials, for these pharmacies
      may not be available in the pharmacy you use for up-to-date information
      about our network pharmacies, including whether there are any lower end
      cost preferred pharmacies in your area, please call customer care at
      <ClickToCall v-slot="{ handleClick }">
        <a :href="phone.uri" @click="handleClick">
          {{ phone.formatted }}
        </a>
      </ClickToCall>
      (TTY: 711) or consult the online pharmacy directory at
      <a href="https://humana.com">Humana.com</a>.

      <br />
      <br />

      {{ brand.url }} is not connected with or endorsed by the United States
      government or the Federal Medicare program. There is absolutely no
      obligation to purchase anything. At {{ brand.url }} you will talk with a
      licensed insurance agent in your state. This is a solicitation of
      insurance. Applying, Choosing or Enrollment in a plan may be limited to
      certain times of the year unless you qualify for a special enrollment
      period or you are in your Medicare Initial Election Period.

      <br />
      <br />

      By using this site, you acknowledge that you have read and agree to the
      <Link to="/tos">Terms of Service</Link>
      and
      <Link to="/privacy">Privacy Policy</Link>.

      <br />
      <br />

      <FooterBenefits />

      <div class="sm:hidden">
        <div class="flex items-center space-x-6 my-2">
          <img src="../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../assets/comodo-badge.svg" width="107" height="34" />
        </div>
        <br />
      </div>

      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
      reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>
    </div>
  </div>
</template>
