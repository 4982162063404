<script setup lang="ts">
const { onSubmit } = inject<any>('form')

useTimeoutFn(() => {
  onSubmit()
}, 2000)
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Searching for a licensed insurance agent in your area...
  </div>

  <div class="flex justify-center">
    <Spinner />
  </div>
</template>
