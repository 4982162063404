import { Product } from '~/modules/product'

export interface PageOptions {
  name: string
  category: string
  product?: Product
  [key: string]: any
}

interface Analytics {
  page: (options: PageOptions) => void
  track: (event: string, props?: object) => void
  ready: (callback: () => void) => void
}

const defaultProps = () => {
  return {
    website: brand.id,
    sessionId: getSession().id,
    isPaidUser: getSessionEnhanced().isPaidUser,
    gaClientId: ga.clientId,
    userAgent: userAgent.value,
    language: language.value,
    smid: smid.value,
    botDetectionResult: botDetectionResult.value,
    fbclid: query.fbclid,
    gclid: query.gclid,
    tblci: query.tblci,
    token: query.token,
    utm_source: query.utm_source,
    utm_medium: query.utm_medium,
    utm_campaign: query.utm_campaign,
    utm_content: query.utm_content,
    utm_term: query.utm_term,
    session: {
      fbclid: getSessionEnhanced().query.fbclid,
      gclid: getSessionEnhanced().query.gclid,
      tblci: getSessionEnhanced().query.tblci,
      token: getSessionEnhanced().query.token,
      utm_source: getSessionEnhanced().query.utm_source,
      utm_medium: getSessionEnhanced().query.utm_medium,
      utm_campaign: getSessionEnhanced().query.utm_campaign,
      utm_content: getSessionEnhanced().query.utm_content,
      utm_term: getSessionEnhanced().query.utm_term,
    },
    ipLookup: ip.lookup && _.pick(ip.lookup, ['stateCode', 'postalCode']),
  }
}

const defaultPropsTrack = () => {
  return {
    trustedFormToken: trustedForm.token,
  }
}

export const analytics: Analytics = {
  track: (event, props) => {
    if (!import.meta.env.SSR) {
      window.analytics.track(event, {
        ...defaultProps(),
        ...defaultPropsTrack(),
        ...props,
      })
      axios
        .post(
          import.meta.env.VITE_EH_INTERNAL_API_URL +
            '/segment/archive/web-events',
          {
            type: 'track',
            timestamp: new Date().toISOString(),
            sessionId: getSession().id,
            anonymousId: segment.anonymousId,
            properties: {
              ...defaultProps(),
              ...defaultPropsTrack(),
              ...props,
              website: brand.id,
              url: {
                full: window.location.href,
                path: window.location.pathname,
                query,
              },
            },
            event,
          },
          {
            headers: {
              'x-api-key': import.meta.env.VITE_EH_INTERNAL_API_KEY,
            },
          }
        )
        .then(() => {})
        .catch(() => {})
      extendSession()
    }
  },
  ready: (callback) => {
    if (!import.meta.env.SSR) {
      if (window.analyticsReady) callback()
      else window.addEventListener('analyticsready', callback)
    }
  },
  page: ({ name, category, product = null, ...props }) => {
    if (!import.meta.env.SSR) {
      window.analytics.page(category, name, {
        ...defaultProps(),
        ...props,
        product,
      })
      axios
        .post(
          import.meta.env.VITE_EH_INTERNAL_API_URL +
            '/segment/archive/web-events',
          {
            type: 'page',
            timestamp: new Date().toISOString(),
            sessionId: getSession().id,
            anonymousId: segment.anonymousId,
            properties: {
              ...defaultProps(),
              ...props,
              product,
              website: brand.id,
              url: {
                full: window.location.href,
                path: window.location.pathname,
                query,
              },
            },
            name,
            category,
          },
          {
            headers: {
              'x-api-key': import.meta.env.VITE_EH_INTERNAL_API_KEY,
            },
          }
        )
        .then(() => {})
        .catch(() => {})
      dataLayer.push({ event: 'pageView' })
      extendSession()
    }
  },
}
