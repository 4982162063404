<template>
  <div class="bg-white py-6 sticky top-0 z-20">
    <slot name="banner" />
    <div class="container flex justify-between gap-4">
      <div class="flex items-center justify-start space-x-6">
        <RouterLink to="/">
          <LogoSmall class="sm:hidden" />
          <Logo class="hidden sm:block" />
        </RouterLink>
        <div class="w-[1px] h-full py-5 bg-gray-400 hidden md:block"></div>
        <span class="text-sx hidden md:block">A Better <br />Way To Care</span>
      </div>
      <Button variant="blue" size="sx" :to="`/paap/save-money-stay-healthy`">
        Learn More About Value-Based Care!
      </Button>
    </div>
  </div>
</template>
