<script setup lang="ts">
useHead({
  title: `Get a quote - ${brand.name}`,
})

enterExperiment('carrierTyp')
</script>

<template>
  <Layout class="container flex flex-col">
    <div class="flex-1 py-16">
      <Form
        id="v2"
        submitHandler="leadgen"
        :successPage="
          exp?.carrierTyp?.carrierTyp
            ? '/quotes/v2/top-carriers'
            : '/quotes/v2/thank-you'
        "
        :failurePage="
          exp?.carrierTyp?.carrierTyp
            ? '/quotes/v2/top-carriers'
            : '/quotes/v2/thank-you'
        "
        :steps="[
          { id: 'zipcode', skip: true },
          { id: 'medicareab' },
          { id: 'name', skip: true },
          { id: 'emailAndPhone' },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "eh"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_EHWEB092201_C"
  }
}
</route>
