<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      We do not offer every plan available in your area. Currently we represent
      over 22 organizations which offer 38 products in your area. Please contact
      Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program
      (SHIP) to get information on all of your options.

      <br />
      <br />

      Not all plans offer all of these benefits. Benefits may vary by carrier
      and location. Limitations and exclusions may apply.

      <br />
      <br />

      The Humana
      <img
        src="../assets/pdp.png"
        width="159"
        height="24"
        class="inline-block align-bottom"
        alt="PDP"
      />
      (PDP) pharmacy network includes limited, lower-cost, preferred pharmacies
      in urban areas of Arkansas, Connecticut, Delaware, Iowa, Indiana,
      Kentucky, Maine, Michigan, Minnesota, Missouri, Mississippi, North Dakota,
      New York, Ohio, Puerto Rico, Rhode Island, South Dakota, Tennessee,
      Vermont, Wisconsin, West Virginia; suburban areas of Connecticut, Hawaii,
      Massachusetts, Maine, Michigan, Montana, North Dakota, New Jersey, New
      York, Ohio, Pennsylvania, Puerto Rico, Rhode Island, West Virginia; and
      rural areas of Iowa, Minnesota, Montana, North Dakota, Nebraska, South
      Dakota, Vermont, Wyoming. There are an extremely limited number of
      preferred cost share pharmacies in urban areas in the following states:
      Arkansas, Delaware, Maine, Michigan, Minnesota, Mississippi, North Dakota,
      New York, Ohio, Rhode Island, and South Dakota; suburban areas of Montana,
      and North Dakota; and rural areas of North Dakota. The lower costs
      advertised in our plan materials for these pharmacies may not be available
      at the pharmacy you use. For up-to-date information about our network
      pharmacies, including whether there are any lower-cost preferred
      pharmacies in your area, please call Customer Care at
      <ClickToCall v-slot="{ handleClick }">
        <a :href="phone.uri" @click="handleClick">
          {{ phone.formatted }}
        </a>
      </ClickToCall>
      (TTY: 711) or consult the online pharmacy directory at
      <a href="https://humana.com">Humana.com</a>.

      <br />
      <br />

      Allowance amounts cannot be combined with other benefit allowances.
      Limitations and restrictions may apply.

      <br />
      <br />

      Participating sales agencies represent Medicare Advantage [HMO, PPO and
      PFFS] organizations [and stand-alone PDP
      <img
        src="../assets/pdp-lower.png"
        width="162"
        height="24"
        class="inline-block align-bottom"
        alt="PDP"
      />] that are contracted with Medicare. Enrollment depends on the plan's
      contract renewal.

      <br />
      <br />

      {{ brand.url }} is not connected with or endorsed by the United States
      government or the Federal Medicare program. There is absolutely no
      obligation to purchase anything. At {{ brand.url }} you will talk with a
      licensed insurance agent in your state. This is a solicitation of
      insurance. Enrollment in a plan may be limited to certain times of the
      year unless you qualify for a special enrollment period or you are in your
      Medicare Initial Election Period.

      <br />
      <br />

      By using this site, you acknowledge that you have read and agree to the
      <Link to="/tos">Terms of Service</Link>
      and
      <Link to="/privacy">Privacy Policy</Link>.

      <br />
      <br />

      <div class="sm:hidden">
        <div class="flex items-center space-x-6 my-2">
          <img src="../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../assets/comodo-badge.svg" width="107" height="34" />
        </div>
        <br />
      </div>

      Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All rights
      reserved | 8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069

      <template v-if="smid">
        <br />
        <br />
        {{ smid }}
      </template>
    </div>
  </div>
</template>
