<script setup lang="ts">
import { PageOptions } from '~/modules/analytics'

const props = defineProps<{
  frontmatter: {
    head?: any
    page?: PageOptions
    layout?: {
      name: string
      props?: object
    }
  }
}>()

if (props.frontmatter.head) {
  useHead({
    ...props.frontmatter.head,
    title: props.frontmatter.head.title?.replace('BRAND', brand.name),
  })
}

if (props.frontmatter.page) {
  analytics.page(props.frontmatter.page)
}

const layouts: any = _.mapKeys(
  import.meta.glob('./layout/*.vue', { eager: true }),
  (value, key) => _.camelCase(key.slice(15, -4) || 'default')
)

const layout = props.frontmatter.layout && {
  component: layouts[props.frontmatter.layout.name].default,
  props: props.frontmatter.layout.props,
}
</script>

<template>
  <component v-if="layout" :is="layout.component" v-bind="layout.props">
    <slot />
  </component>
  <slot v-else />
</template>
