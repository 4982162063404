<script setup lang="ts">
const emit = defineEmits(['close'])

const answer = ref<'thumbsUp' | 'thumbsDown' | null>(null)

whenever(answer, () => {
  analytics.track('postCallReviewAnswer', {
    answer: answer.value,
  })
})

onMounted(() => {
  analytics.track('postCallReviewShown')
})

const textOptedIn = ref(false)

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  analytics.track('smsConsentGiven', {
    phone: values.primaryphone,
    trustedForm: trustedForm.certUrl,
    origin: 'postCallReview'
  })

  textOptedIn.value = true
})
</script>

<template>
  <div class="p-8">
    <div
      class="absolute top-3 right-3 p-1 -m-1 rounded-full cursor-pointer hover:bg-gray-100 text-gray-400 hover:text-gray-500"
      @click="emit('close')"
    >
      <Icon i-heroicons-outline:x />
    </div>

    <template v-if="!answer">
      <div class="text-3xl mb-8 text-center"> How was your call? </div>
      <div class="flex items-center justify-center gap-4">
        <div class="bg-gray-200 p-4 rounded-md" @click="answer = 'thumbsUp'">
          <Icon i-heroicons-outline:thumb-up class="h-8 w-8" />
        </div>
        <div class="bg-gray-200 p-4 rounded-md" @click="answer = 'thumbsDown'">
          <Icon i-heroicons-outline:thumb-down class="h-8 w-8" />
        </div>
      </div>
      <div class="h-px bg-gray-200 my-8"></div>
      <div class="flex justify-center">
        <ClickToCall
          no-post-call-review
          :props="{ ctcLocation: 'post-call-review-modal' }"
          v-slot="{ handleClick }"
        >
          <Button variant="darkBlue" :to="phone.uri" @click="handleClick">
            <Icon i-heroicons-solid:phone class="w-8 h-8 mr-4" />
            <span> Call back </span>
          </Button>
        </ClickToCall>
      </div>
    </template>

    <template v-else-if="textOptedIn">
      <div class="text-3xl text-center"> Thank You! </div>
    </template>

    <form v-else @submit="onSubmit">
      <div class="text-2xl mb-8 text-center">
        Would you like us to text a call recap to you?
      </div>

      <Field
        type="phone"
        name="primaryphone"
        label="Phone number"
        rules="required"
      />

      <Button wide type="submit" class="my-8"> Submit </Button>

      <div class="text-gray-500 text-sm text-justify">
        This is a solicitation for insurance and related services and products
        including Medicare Advantage, Medicare Supplement Insurance, and
        Medicare Prescription Drug Plans and healthcare provider services. There
        is no obligation to enroll. Your inquiry may generate marketing and
        other communications by a licensed insurance agency or other insurance
        company or on behalf of a healthcare provider. Submission of your
        information does NOT affect your current enrollment, nor will it enroll
        you in a Medicare Advantage, Medicare Supplement Insurance plan, or
        other Medicare plan.
        <br />
        <br />
        By clicking the button on this page, you provide expressed consent to
        receive marketing and other communications from EasyHealth, its
        subsidiaries, and its partner
        <Link to="/carriers" target="_blank">companies</Link> via email, live
        telephone, automatic telephone dialing system, artificial or
        pre-recorded voice message, or SMS/MMS text message at the telephone
        number provided even if the number is on a federal, state, or company Do
        Not Call list. Your consent to receive communications is not required as
        a condition of purchasing any goods or services. Your telephone company
        may impose additional charges for text messages, and you may revoke your
        consent at any time through any reasonable manner including by
        contacting info@joineasyhealth.com.
        <br />
        <br />
        Please see our <Link to="/tos"> Terms and Conditions </Link> and
        <Link to="/privacy"> Privacy Policy </Link> for additional terms.
      </div>
    </form>
  </div>
</template>
