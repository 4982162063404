<script setup lang="ts">
import { InputHTMLAttributes } from 'vue'
import Cleave from 'cleave.js'

const props = defineProps<{
  type?: 'text' | 'zip' | 'email' | 'phone' | 'date'
  name: string
  rules?: string
  label?: string
  disabled?: boolean
  password?: boolean
  optional?: boolean
  inputmode?: InputHTMLAttributes['inputmode']
  placeholder?: InputHTMLAttributes['placeholder']
  autocomplete?: InputHTMLAttributes['autocomplete']
  initialValue?: string | null
}>()

const form: any = inject('form', null)

const id = 'id' + Math.random().toFixed(10).slice(2)

let autocomplete = props.autocomplete
let placeholder = props.placeholder
let inputmode = props.inputmode
let rules = props.rules || ''
let mask: object | undefined
let initialValue =
  (form && forms[form.id]?.[props.name]) || props.initialValue || ''

switch (props.type) {
  case 'zip':
    autocomplete = autocomplete ?? 'postal-code'
    placeholder = placeholder ?? '00000'
    inputmode = inputmode ?? 'numeric'
    rules = `${rules}|zip`
    mask = {
      blocks: [5],
      numericOnly: true,
    }
    break

  case 'email':
    autocomplete = autocomplete ?? 'email'
    inputmode = inputmode ?? 'email'
    rules = `${rules}|email`
    break

  case 'date':
    placeholder = placeholder ?? 'MM/DD/YYYY'
    inputmode = inputmode ?? 'numeric'
    rules = `${rules}|date`
    mask = {
      date: true,
      delimiter: '/',
      datePattern: ['m', 'd', 'Y'],
    }
    break

  case 'phone':
    autocomplete = autocomplete ?? 'tel-national'
    placeholder = placeholder ?? '000-000-0000'
    inputmode = inputmode ?? 'numeric'
    rules = `${rules}|phone`
    mask = {
      blocks: [3, 3, 4],
      numericOnly: true,
      delimiter: '-',
    }
    initialValue = initialValue.replace(/\D/g, '')
    if (/^1\d{10}$/.test(initialValue)) {
      initialValue = initialValue.slice(1)
    }
    break
}

const { value, errorMessage, meta } = useField(props.name, rules, {
  initialValue,
})

const focused = ref(false)
const showError = computed(() => {
  return errorMessage.value && !focused.value
})

onMounted(() => {
  if (mask) {
    new Cleave(`#${id}`, {
      ...mask,
      onValueChanged: (event) => {
        value.value = event.target.value
      },
    })
  }
})

const isSubmitting = useIsSubmitting()

const disabled = computed(() => {
  return isSubmitting.value || props.disabled
})

const valid = computed(() => {
  return meta.valid && !!value.value.trim()
})

const showIcon = computed(() => {
  return (
    (!focused.value && (errorMessage.value || valid.value)) ||
    (focused.value &&
      valid.value &&
      props.type &&
      ['zip', 'email', 'phone'].includes(props.type))
  )
})

defineExpose({
  value,
})
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="inline-block mb-1 text-lg sm:text-xl font-medium text-gray-700 cursor-pointer"
    >
      {{ label }}
      <span v-if="optional" class="text-gray-500"> (optional) </span>
    </label>
    <div class="relative">
      <input
        v-model="value"
        :id="id"
        :name="name"
        :type="password ? 'password' : 'text'"
        :disabled="disabled"
        :inputmode="inputmode"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :class="[
          showError ? 'placeholder-red-300' : 'placeholder-gray-400',
          disabled ? 'bg-gray-100' : 'bg-white',
          disabled
            ? 'text-gray-500'
            : showError
            ? 'text-red-900'
            : 'text-gray-900',
          focused
            ? 'border-blue ring ring-blue'
            : valid
            ? 'border-green-700 ring ring-green-700'
            : errorMessage
            ? 'border-red ring ring-red'
            : 'border-gray-300',
          showIcon ? 'pr-10' : 'pr-5',
          'block w-full py-3 pl-5 border rounded-md shadow-sm text-lg outline-none [text-align:inherit]',
        ]"
        @focus="focused = true"
        @blur="focused = false"
      />
      <div
        v-if="showIcon"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <Icon
          v-if="focused || valid"
          i-heroicons-solid:check-circle
          class="text-green-700"
        />
        <Icon v-else i-heroicons-solid:exclamation-circle class="text-red" />
      </div>
    </div>
    <div v-if="showError" class="mt-1 text-base sm:text-lg text-red">
      {{ errorMessage }}
    </div>
  </div>
</template>
