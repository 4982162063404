<template>
  <FormProgress />

  <FormTitle> What's your ZIP code? </FormTitle>

  <Field
    type="zip"
    name="zipcode"
    label="ZIP code"
    rules="required"
    :initialValue="query.zip"
  />

  <FormButtonNext />
</template>
