<script setup lang="ts">
const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`/go-4-benefits/?zip=${values.zip}`)
})
</script>

<template>
  <form @submit="onSubmit" class="space-y-4">
    <Field
      type="zip"
      name="zip"
      rules="required"
      placeholder="Enter ZIP Code"
      class="text-center"
    />

    <Button wide type="submit" class="whitespace-nowrap">
      Compare Plans
    </Button>
  </form>
</template>
