type Step = {
  id: string
  number: number
  values: Record<string, any>
}

export const generateGptMessage = async (step: Step) => {
  if (step.values.worryHealthcareCosts === 'A lot') {
    return 'We understand your concerns about healthcare costs. By continuing, we can help you find a Medicare plan that fits your budget and needs.'
  }

  throw new Error()
}
