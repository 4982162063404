<script setup lang="ts">
const { prevStep, goTo } = inject('form')!
</script>

<template>
  <div class="flex justify-center">
    <Button size="sm" variant="gray" @click="goTo(prevStep, false)">
      <Icon i-heroicons-solid:arrow-left class="mr-2" />
      <span> Back </span>
    </Button>
  </div>
</template>
