<script setup lang="ts">
type BenefitLoading = {
  id: string
  loading: true
  error: false
  data: null
}

type BenefitError = {
  id: string
  loading: false
  error: true
  data: null
}

type BenefitSuccess = {
  id: string
  loading: false
  error: false
  data: {
    link: string
    zipCode: string
    stateCode: string
    stateName: string
    planCount: number
    minAmount?: number | null
    maxAmount?: number | null
    avg?: number | null
  }
}

type Benefit = BenefitLoading | BenefitError | BenefitSuccess

const benefits = ref<Benefit[]>([])

const route = useRoute()

const hostname = ref<string>()

onMounted(() => {
  hostname.value = location.hostname

  const routeBenefits = (route.meta.benefits as string[] | undefined) || []

  routeBenefits.forEach(async (benefitId) => {
    const benefitIndex = benefits.value.length

    benefits.value.push({
      id: benefitId,
      loading: true,
      error: false,
      data: null,
    })

    await until(() => ip.lookup).toBeTruthy({ timeout: 5000 })

    const zipCode = ip.lookup?.postalCode
    const stateCode = ip.lookup?.stateCode
    const stateName = ip.lookup?.stateName

    if (!zipCode || !stateCode || !stateName) {
      benefits.value[benefitIndex] = {
        id: benefitId,
        loading: false,
        error: true,
        data: null,
      }
      return
    }

    axios
      .get(`https://website-api-65sjg55l7q-uc.a.run.app/benefits/summary`, {
        params: {
          zip_code: zipCode,
          benefit: benefitId,
          include_plans: false,
        },
        withCredentials: true,
      })
      .then((response) => {
        const summary = response.data.summary

        if (!summary.planCount) {
          throw new Error()
        }

        const benefitValueFieldById: Record<string, string | undefined> = {
          giveback: 'pbp_d_mco_pay_reduct_amt',
          otc: 'annual_otc_benefit_value',
        }

        const benefitValueField = benefitValueFieldById[benefitId]

        benefits.value[benefitIndex] = {
          id: benefitId,
          loading: false,
          error: false,
          data: {
            link: `/location-plans?zip=${zipCode}&benefit=${benefitId}`,
            zipCode,
            stateCode,
            stateName,
            planCount: summary.planCount,
            minAmount: benefitValueField && summary.min?.[benefitValueField],
            maxAmount: benefitValueField && summary.max?.[benefitValueField],
            avg: summary.avg,
          },
        }
      })
      .catch(() => {
        benefits.value[benefitIndex] = {
          id: benefitId,
          loading: false,
          error: true,
          data: null,
        }
      })
  })
})
</script>

<template>
  <template v-for="benefit in benefits" :key="benefit.id">
    <template v-if="benefit.id === 'giveback'">
      <template v-if="benefit.loading"></template>
      <template v-else-if="benefit.error">
        We are unable to verify if Part B Buyback / Giveback benefits are
        available in your area. Please enter your zipcode to confirm plan
        availability.
        <Link to="/location-plans?benefit=giveback"
          >{{ hostname }}/location-plans?benefit=giveback</Link
        >
        <br />
        <br />
      </template>
      <template v-else>
        Based on CMS data, Part B Premium Buyback / Giveback benefits are
        available from {{ benefit.data.planCount }} plans in
        {{ benefit.data.zipCode }}, the highest amount of
        {{ benefit.data.maxAmount }} per year and an average benefit of
        {{ benefit.data.avg }} per year. A full list of plans in your service
        area with this benefit can be found here (<Link :to="benefit.data.link"
          >{{ hostname }}{{ benefit.data.link }}</Link
        >).
        <br />
        <br />
      </template>
    </template>

    <template v-if="benefit.id === 'otc'">
      <template v-if="benefit.loading"></template>
      <template v-else-if="benefit.error">
        We are unable to verify if OTC benefits are available in your area.
        Please enter your zipcode to confirm plan availability.
        <Link to="/location-plans?benefit=otc"
          >{{ hostname }}/location-plans?benefit=otc</Link
        >
        <br />
        <br />
      </template>
      <template v-else>
        Based on CMS data, over the counter benefits are available from
        {{ benefit.data.planCount }} plans in {{ benefit.data.zipCode }}, with a
        maximum benefit value of {{ benefit.data.maxAmount }} per year and an
        average benefit of {{ benefit.data.avg }} per year. A full list of plans
        in your service area with this benefit can be found here (<Link
          :to="benefit.data.link"
          >{{ hostname }}{{ benefit.data.link }}</Link
        >).
        <br />
        <br />
      </template>
    </template>
  </template>
</template>
