<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center">
    Congratulations!
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Speak with a licensed insurance agent and get answers to your questions
    about Medicare Advantage plans.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
</template>
