<script setup lang="ts">
const props = defineProps<{
  name: string
  label?: string
  disabled?: boolean
  initialValue: string
  options: {
    value: string
    text: string
  }[]
}>()

const id = 'id' + Math.random().toFixed(10).slice(2)

const { value } = useField(props.name, undefined, {
  initialValue: props.initialValue,
})

const isSubmitting = useIsSubmitting()

const disabled = computed(() => {
  return isSubmitting.value || props.disabled
})
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="inline-block mb-1 text-lg sm:text-xl font-medium text-gray-700 cursor-pointer"
    >
      {{ label }}
    </label>
    <div class="relative">
      <select
        v-model="value"
        :id="id"
        :name="name"
        :disabled="disabled"
        :class="[
          disabled && 'bg-gray-100 text-gray-500 opacity-100',
          'block w-full py-3 pl-5 pr-14 border border-gray-300 rounded-md shadow-sm text-lg outline-none appearance-none',
        ]"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <div
        class="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none"
      >
        <Icon i-heroicons-outline:chevron-down class="text-gray-400" />
      </div>
    </div>
  </div>
</template>
