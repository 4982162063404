import { StorageSerializers } from '@vueuse/core'

interface Experiments {
  [key: string]: {
    id: string
    enabled: boolean
    variants: {
      [key: string]: number
    }
  }
}

const defineExperiments = <T extends Experiments>(experiments: T) => experiments

const experiments = defineExperiments({
  houseAd: {
    id: '2022-11-21-house-ad',
    enabled: true,
    variants: {
      show: 50,
      hide: 50,
    },
  },
  carrierTyp: {
    id: '2022-12-01-carrier-typ',
    enabled: false,
    variants: {
      carrierTyp: 50,
      originalTyp: 50,
    },
  },
  helplineMa: {
    id: 'helpline-ma-2023-03-08',
    enabled: true,
    variants: {
      original: 0,
      helpline: 100,
    },
  },
  helplineFacebook: {
    id: 'helpline-facebook-2023-03-22',
    enabled: true,
    variants: {
      original: 50,
      helpline: 50,
    },
  },
  groceryAllowanceFormVsCtc: {
    id: 'grocery-allowance-form-vs-ctc-2023-05-15',
    enabled: true,
    variants: {
      form: 50,
      ctc: 50,
    },
  },
  go4BenefitsCopy: {
    id: 'go-4-benefits-copy-2023-06-27',
    enabled: false,
    variants: {
      spendingAllowances3300: 50,
      groceryAllowance900: 50,
    },
  },
  testimonials: {
    id: 'testimonials-2023-07-05',
    enabled: true,
    variants: {
      show: 0,
      hide: 1,
    },
  },
  buyback2024Image: {
    id: 'buyback-2024-image-2023-08-30',
    enabled: true,
    variants: {
      couple: 1,
      olderCouple: 0,
      original: 1,
      seniorsHandsUp: 1,
    },
  },
  helplineDateAndCard: {
    id: 'helpline-date-and-card-2023-09-01',
    enabled: true,
    variants: {
      show: 1,
      hide: 1,
    },
  },
  newHealthyFoodsImage: {
    id: 'new-healthy-foods-image-2023-09-06',
    enabled: true,
    variants: {
      fruits: 1,
      womanRedCard: 1,
      coupleHoldingCard: 1,
    },
  },
} as const)

interface EnteredExperiment {
  id: string
  name: string
  variant: string
}

const enteredExperiments = useLocalStorage<EnteredExperiment[]>(
  'enteredExperiments',
  []
)

type Exp =
  | null
  | {
      [ExperimentName in keyof typeof experiments]?: {
        [VariantName in keyof typeof experiments[ExperimentName]['variants']]?: true
      }
    }

export const exp = computed<Exp>(() => {
  const runningEnteredExperiments = enteredExperiments.value.filter(
    (experiment) => _.some(experiments, ['id', experiment.id])
  )
  return runningEnteredExperiments.length
    ? runningEnteredExperiments.reduce((acc: any, experiment) => {
        acc[experiment.name] = {
          [experiment.variant]: true,
        }
        return acc
      }, {})
    : null
})

export const enterExperiment = (name: keyof typeof experiments) => {
  if (!import.meta.env.SSR && !exp.value?.[name]) {
    const { id, variants, enabled } = experiments[name] as Experiments['key']

    if (!enabled) return

    const variant = _.sample(
      _.flatMap(variants, (weight, name) => _.times(weight, () => name))
    )!

    const enteredExperiment = { id, name, variant }

    enteredExperiments.value = [...enteredExperiments.value, enteredExperiment]

    analytics.track('enteredExperiment', enteredExperiment)
  }
}

export const forceEnterExperiment = (id: string, variant: string) => {
  if (!import.meta.env.SSR) {
    const experiment = _.find(experiments, (experiment) => {
      return experiment.id === id
    })

    if (experiment && variant in experiment.variants) {
      const enteredExperiment = {
        id,
        name: _.findKey(experiments, (experiment) => experiment.id === id)!,
        variant,
      }

      enteredExperiments.value = [
        ...enteredExperiments.value.filter(
          (experiment) => experiment.id !== id
        ),
        enteredExperiment,
      ]
    }
  }
}

// TODO: remove when all running experiments started after 2022-11-23

const expData = useLocalStorage<EnteredExperiment | null>('expData', null, {
  serializer: StorageSerializers.object,
})

onInit(({ isClient }) => {
  if (isClient) {
    if (
      expData.value &&
      !enteredExperiments.value.some(
        (experiment) => experiment.id === expData.value!.id
      )
    ) {
      enteredExperiments.value = [...enteredExperiments.value, expData.value]
      expData.value = null
    }
  }
})
