import { ViteSSGContext } from 'vite-ssg'
import { BrowserTracing } from '@sentry/tracing'

type Hook = (ctx: ViteSSGContext) => void

const hooks: Hook[] = []

export const onInit = (hook: Hook) => {
  hooks.push(hook)
}

export const init: Hook = (ctx) => {
  if (ctx.isClient) {
    Sentry.init({
      app: ctx.app,
      dsn: 'https://e7d8163c472644179c4cc70f62e29657@o546964.ingest.sentry.io/6654910',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(ctx.router),
          tracePropagationTargets: [],
        }),
      ],
      tracesSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 1,
      environment: import.meta.env.MODE,
      release: __COMMIT__ || undefined,
    })
  }

  hooks.forEach((hook) => {
    hook(ctx)
  })
}
