import mapKeys from 'lodash/mapKeys'
import camelCase from 'lodash/camelCase'
import omitBy from 'lodash/omitBy'
import clamp from 'lodash/clamp'
import findLast from 'lodash/findLast'
import nth from 'lodash/nth'
import every from 'lodash/every'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import find from 'lodash/find'
import some from 'lodash/some'
import sample from 'lodash/sample'
import flatMap from 'lodash/flatMap'
import times from 'lodash/times'
import findKey from 'lodash/findKey'
import once from 'lodash/once'
import fromPairs from 'lodash/fromPairs'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'

export const _ = {
  mapKeys,
  camelCase,
  omitBy,
  clamp,
  findLast,
  nth,
  every,
  mapValues,
  pick,
  find,
  some,
  sample,
  flatMap,
  times,
  findKey,
  once,
  fromPairs,
  isEmpty,
  isArray,
  omit,
  isEqual,
  sortBy,
  last,
}
