<script setup lang="ts">
const route = useRoute()

watchSyncEffect(() => {
  const routeSmid = route.meta.smid as
    | string
    | Record<string, string>
    | undefined

  smid.value = routeSmid
    ? typeof routeSmid === 'string'
      ? routeSmid
      : routeSmid[brand.id] || routeSmid.default || null
    : null

  const routeLanguage = route.meta.language as string | undefined
  language.value = routeLanguage || 'english'
})

useHead({
  meta: [
    {
      name: 'robots',
      content: computed(() => {
        const metaRobots = route.meta.robots as
          | string
          | Record<string, string>
          | undefined

        const brandId =
          !import.meta.env.SSR &&
          window.location.hostname === 'shop.medicare10.com'
            ? 'shop'
            : brand.id

        return metaRobots
          ? typeof metaRobots === 'string'
            ? metaRobots
            : metaRobots[brandId] || metaRobots.default || ''
          : ''
      }),
    },
  ],
})

useFavicon(brand.favicon)
</script>

<template>
  <RouterView />
</template>
