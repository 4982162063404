<script setup lang="ts">
const isSubmitting = useIsSubmitting()
</script>

<template>
  <Button wide size="sm" type="submit" :loading="isSubmitting">
    <slot> Next </slot>
    <Icon i-heroicons-solid:arrow-right class="ml-2" />
  </Button>
</template>
