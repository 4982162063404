<script setup lang="ts">
const { value: currentlyInsured } = useField('currently_insured')
</script>

<template>
  <FormProgress />

  <FormTitle> Do you have health insurance now? </FormTitle>

  <div class="space-y-3">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="currentlyInsured = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="currentlyInsured = false"
    >
      No
    </Button>
  </div>

  <FormButtonBack />
</template>
