interface Query {
  [key: string]: string | null | undefined
}

export const getSession = () => {
  return window.getSession()
}

export const getSessionEnhanced = () => {
  const session = getSession()

  const key = `url_query_params_session_${session.id}`
  const query: Query =
    JSON.parse(String(window.localStorage.getItem(key))) || {}

  const clid = query.fbclid || query.gclid || query.ttclid || query.token

  return {
    ...session,
    query,
    isPaidUser: !!clid && clid.length >= 15 && !!query.utm_campaign,
  }
}

export const extendSession = () => {
  const session = getSession()
  session.dateLastActivity = Date.now()
  window.localStorage.setItem('session', JSON.stringify(session))
}
