import CookieStore from 'universal-cookie'

const cookieStore = new CookieStore()

interface Cookies {
  [key: string]: string | undefined
}

const cookiesRef = ref<Cookies>(cookieStore.getAll())

export const cookies: Cookies = toReactive(cookiesRef)

onInit(({ isClient }) => {
  if (isClient) {
    window.setInterval(() => {
      cookiesRef.value = cookieStore.getAll()
    }, 100)
  }
})
