// @unocss-include

const logos = _.mapKeys(
  import.meta.glob('../assets/logo-*.png', { eager: true, as: 'url' }),
  (value, key) => key.slice(15, -4)
)

const brands = {
  eh: {
    id: 'eh',
    name: 'EasyHealth',
    url: 'EasyHealth.com',
    phone: '18449494157',
    favicon: '/favicon-eh.ico',
    logo: {
      url: logos.eh,
      class: 'max-h-11',
    },
    logoSmall: {
      url: logos['small-eh'],
      class: 'max-h-12',
    },
  },
  m10: {
    id: 'm10',
    name: 'Medicare10',
    url: 'Medicare10.com',
    phone: '18335732845',
    favicon: '/favicon.ico',
    logo: {
      url: logos.m10,
      class: 'max-h-11 w-auto',
      width: 412,
      height: 88,
    },
    logoSmall: {
      url: logos.small,
      class: 'max-h-10',
    },
  },
  ayb: {
    id: 'ayb',
    name: 'Activate Your Benefits',
    url: 'ActivateYourBenefits.org',
    phone: '18663291920',
    favicon: '/favicon.ico',
    logo: {
      url: logos.ayb,
      class: 'max-h-11',
    },
    logoSmall: {
      url: logos.small,
      class: 'max-h-10',
    },
  },
  bh: {
    id: 'bh',
    name: 'Benefit Helpline',
    url: 'Benefit-Helpline.com',
    phone: '18557571250',
    favicon: '/favicon-bh.ico',
    logo: {
      url: logos.bh,
      class: 'max-h-13',
    },
    logoSmall: {
      url: logos['small-bh'],
      class: 'max-h-12',
    },
  },
  pc: {
    id: 'pc',
    name: 'Patient Connectxion',
    url: 'PatientConnectxion.com',
    phone: '18663291920',
    favicon: '/favicon-pc.ico',
    logo: {
      url: logos.pc,
      class: 'max-h-11',
    },
    logoSmall: {
      url: logos['small-pc'],
      class: 'max-h-10',
    },
  },
  med10: {
    id: 'med10',
    name: 'Med10',
    url: 'Med10.org',
    phone: '18335732845',
    favicon: '/favicon.ico',
    logo: {
      url: logos.med10,
      class: 'max-h-6',
    },
    logoSmall: {
      url: logos.small,
      class: 'max-h-10',
    },
  },
  boomer: {
    id: 'boomer',
    name: 'This Boomer',
    url: 'ThisBoomer.com',
    phone: '18444151627',
    favicon: '/favicon-boomer.ico',
    logo: {
      url: logos.boomer,
      class: 'max-h-11',
    },
    logoSmall: {
      url: logos['small-boomer'],
      class: 'max-h-12',
    },
  },
} as const

export const brand = brands[__BRAND__]
