import { ViteSSG } from 'vite-ssg'
import App from './App.vue'
import routes from '~pages'

import.meta.glob('./modules/*.ts', { eager: true })

import '@unocss/reset/tailwind.css'
import './styles/main.css'
import 'uno.css'

export const createApp = ViteSSG(
  App,
  {
    routes,
    scrollBehavior: (to, from, savedPos) => {
      return savedPos
        ? savedPos
        : {
            el: to.hash || '#app',
            behavior: to.name === from.name ? 'smooth' : 'auto',
          }
    },
  },
  init
)
