<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Countdown 2024 calendar - ${brand.name}`,
})

const aepEnd = DateTime.fromISO('2023-12-08T12:00:00Z')
const daysLeft = ref<string>()

onMounted(() => {
  daysLeft.value = aepEnd.diffNow().shiftTo('days').toObject().days!.toFixed()
})
</script>

<template>
  <Layout header="simple3" footer="buyback2024" class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Have Medicare? Turning 65? Some Americans with Medicare A&B in
        {{ ip.lookup?.stateName || 'America' }} are getting
        <span class="text-red">
          extra benefits like dental, vision, or money back*
        </span>
        on their social security with some Medicare Advantage Plans.
      </div>

      <div class="w-64 h-64 mx-auto relative">
        <img src="../../assets/calendar.png" class="w-full h-full" />
        <div
          class="absolute top-28 right-8 bottom-4 left-3 flex items-center justify-center bg-[#e6e6e6]"
        >
          <div class="text-center">
            <div class="text-5xl font-bold"> {{ daysLeft }} </div>
            <div class="mt-1 uppercase font-bold tracking-wider">
              Days left
            </div>
          </div>
        </div>
      </div>

      <UseForm v-slot="{ values }">
        <Form
          id="countdown2024Calendar"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'sorry', if: false },
            { id: 'medicaid' },
            { id: 'us' },
            { id: 'loading' },
            { id: 'call' },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "smid": "MULTI-PLAN_EHWEB062301_M",
    "robots": "noindex"
  }
}
</route>
